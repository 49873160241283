<template>
  <Header
    :titulo="tituloHome"
    :classColor="ttlColor"
    :parrafo="parrafoHome"
    :folder="carpeta"
    :name="nombreImg"
    :bgTriangle="bgTriangleGray"
    :btnLinkHeader="btnLinkHeader"
    :btnText="btnText"
  />
  <section
    class="
      py-5
      bg-gradient-gray
      d-flex
      flex-column
      align-center
      justify-content-center
    "
  >
    <article class="py-5">
      <header>
        <h1 class="text-center" data-aos="zoom-in" data-aos-delay="100">
          Soluciones <span class="f-light">a prueba de cualquier desafío</span>
        </h1>
      </header>
      <div class="container">
        <CardHover class="my-5" />
      </div>
    </article>
  </section>

  <section
    class="vh-80 d-flex justify-content-center align-items-center flex-column"
  >
    <article>
      <div class="container">
        <h1 class="text-center py-5" data-aos="zoom-in" data-aos-delay="100">
          Nuestros <span class="f-light">compañeros de aventuras</span>
        </h1>
      </div>
      <LogosCustomers />
      <div class="text-center mt-5">
        <Button
          :buttonText="textPartners"
          :btnLink="btnLink"
          :class="btnBackground"
        />
      </div>
    </article>
  </section>
  <section
    class="
      bg-gradient-gray
      p-relative
      vh-80
      d-flex
      flex-column
      justify-content-center
    "
  >
    <article class="py-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <h1
              class="my-3 link"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="1000"
            >
              Dónde estamos
            </h1>
            <h2
              class="f-border"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="1000"
            >
              <a href="/paid-media">Posicionando</a><br />
              marcas a lo<br />
              largo del<br />
              continente
            </h2>
          </div>
        </div>
        <img class="img-world" src="../../assets/img/home/Mundo.svg" alt="" />
      </div>
    </article>
  </section>

  <section class="py-5 hide-desktop">
    <article>
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <a href="">
              <img
                class=""
                style="height: 10vh"
                src="../../assets/img/root/InstagramMisc_animated.svg"
                alt=""
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
                data-aos-duration="1000"
              />
            </a>
            <h5
              class="m-0"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="1000"
            >
              Follow us on
            </h5>
            <h1
              class="my-2 f-border-a"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="1000"
            >
              <a href="https://www.instagram.com/itpsmarketing/" target="_blank"
                >Instagram</a
              >
            </h1>
            <a
              href="https://www.instagram.com/itpsmarketing/"
              class="f-border-a"
              target="_blank"
            >
              <h6
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
                data-aos-duration="1000"
              >
                @itpsmarketing
              </h6>
            </a>
          </div>
          <div class="col-lg-8"></div>
        </div>
      </div>
    </article>
  </section>
  <Form />
</template>

<script>
import "@/assets/css/home/style.css";
import Header from "@/components/Header";
import CardHover from "@/components/CardHover";
import LogosCustomers from "@/components/LogosCustomers";
import Button from "@/components/Button";
import Form from "@/components/Form";
export default {
  components: {
    Header,
    CardHover,
    LogosCustomers,
    Form,
    Button,
  },
  data() {
    return {
      tituloHome: "Hacemos lo que amamos.",
      btnLinkHeader: "#contact",
      ttlColor: "ttl-gray",
      carpeta: "home",
      nombreImg: "banana",
      bgTriangleGray: "bg-triangle-gray",
      parrafoHome:
        "Empoderamos el alcance de su marca con estrategias eficaces e ideas innovadoras. ¡En serio, funcionan!",
      btnText: "¡Hagámoslo!",
      textPartners: "¡Comencemos una aventura juntos! Agende una reunión ahora",
      btnLink: "https://meetings.hubspot.com/guadalupe-cieri",
      childclass: "btnHeader",
      btnBackground: "btnAnimate"
    };
  },
  mounted() {
    new TypeIt("#ttl-home", {
      speed: 100,
      waitUntilVisible: true,
      loop: true,
    })
      .type("Hacemos lo que amamos.")
      .move(-15, { delay: 1000, speed: 100 })
      .delete(7, { delay: 1500, speed: 100 })
      .type("Amamos")
      .move(null, { to: "END" })
      .delete(8, { speed: 100 })
      .type(" hacemos.")
      .pause(5000)
      .go();
  },
};
</script>

<style>
.bg-triangle-gray {
  background-image: url("../../assets/img/home/triangle_gray.png");
  background-size: 90vh;
  height: 100vh;
  background-position: bottom right;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-world {
  position: absolute;
  right: 0;
  height: 80vh;
  top: 0;
}
.testclass {
  border: 10px solid red;
}
.hide-desktop{
  display: none;
}
@media (max-width: 1000px) {
  .vh-50 {
    height: 70vh;
    text-align: center;
  }
  .img-world {
    display: none;
  }
  .hide-desktop{
    display: block;
  }
}

.container {
  max-width: 80vw !important;
}
@media(max-width: 1080px){
  .container{
    max-width: 90vw!important;
  }
}
.testclass {
  border: 10px solid red;
}

</style>