<template>
  <div class="container">
    <div class="row justify-content-center text-center">
      <div class="col-6 col-lg-3 logos">
        <img
          class="logos-customers magic-hover magic-hover__square circle"
          src="./../assets/img/logos/LogoHuawei.svg"
          alt="images logos"
        />
      </div>
      <div class="col-6 col-lg-3 my-auto logos">
        <img
          class="simple logos-customers magic-hover magic-hover__square circle"
          src="./../assets/img/logos/SimpleLogo.svg"
          alt="images logos"
          
        />
      </div>
      <div class="col-6 col-lg-3 my-auto logos">
        <img
          class="bagovit logos-customers magic-hover magic-hover__square circle"
          src="./../assets/img/logos/BagovitLogo.svg"
          alt="images logos"
          
        />
      </div>
      <div class="col-6 col-lg-3 logos">
        <img
          class="vital logos-customers magic-hover magic-hover__square circle"
          src="./../assets/img/logos/VitalLogo.svg"
          alt="images logos"
          
        />
      </div>

      <div class="col-6 col-lg-3 my-auto logos">
        <img
          class="ser logos-customers magic-hover magic-hover__square circle"
          src="./../assets/img/logos/SerLogo.svg"
          alt="images logos"
          
        />
      </div>
      <div class="col-6 col-lg-3 my-auto logos">
        <img
          class="yys logos-customers magic-hover magic-hover__square circle"
          src="./../assets/img/logos/Yogurisimo.svg"
          alt="images logos"
          
        />
      </div>
      <div class="col-6 col-lg-3 logos">
        <img
          class="logos-customers magic-hover magic-hover__square circle"
          src="./../assets/img/logos/SerenitoLogo.svg"
          alt="images logos"
        />
      </div>
      <div class="col-6 col-lg-3 logos">
        <img
          class="logos-customers magic-hover magic-hover__square circle"
          src="./../assets/img/logos/CognitusLogo.svg"
          alt="images logos"
        />
      </div>
      <div class="col-6 col-lg-3 logos">
        <img
          class="logos-customers magic-hover magic-hover__square circle"
          src="./../assets/img/logos/RGBLogo.svg"
          alt="images logos"
        />
      </div>
      <div class="col-6 col-lg-3 my-auto logos">
        <img
          class="forbex logos-customers magic-hover magic-hover__square circle"
          src="./../assets/img/logos/ForbexLogo.svg"
          alt="images logos"
          
        />
      </div>
      <div class="col-6 col-lg-3 my-auto logos">
        <img
          class="flex logos-customers magic-hover magic-hover__square circle"
          src="./../assets/img/logos/FlexCWHLogo.svg"
          alt="images logos"
          
        />
      </div>
      <div class="col-6 col-lg-3 my-auto logos">
        <img
          class="eai logos-customers magic-hover magic-hover__square circle"
          src="./../assets/img/logos/EAILogo.svg"
          alt="images logos"
          
        />
      </div>
    </div>
  </div>
</template>

<script>
import lax from "lax.js";
export default {
  name: "LogosCustomers",
  
};
</script>

<style scoped>
.simple{
  height: 50px;
  width: auto;
}
.bagovit{
  height: 50px;
  width: auto;
}
.vital{
  height: 80px;
  width: auto;
}
.ser{
  height: 60px;
  width: auto;
}
.yys{
  height: 60px;
  width: auto;
}
.forbex{
  height: 45px;
  width: auto;
}
.flex{
  height: 55px;
  width: auto;
}
.eai{
  height: 55px;
  width: auto;
}
@media(max-width: 1000px){
  .simple{
    height: 50px;
  }
  .bagovit{
    height: 50px;
  }
  .vital{
    height: 70px;
  }
  .ser{
    height: 50px;
  }
  .yys{
    height: 50px;
  }
  .forbex{
    height: 40px;
  }
  .flex{
    height: 50px;
  }
  .eai{
    height: 50px;
  }
}
</style>