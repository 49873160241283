<template>
  <section class="bg-gradient-triangle">
    <div class="bg-gradient-anim ttl-gray">
      <div class="container pt-5">
        <h6 class="ttl-gray f-light mb-0 pt-5">Quienes somos</h6>
        <h1 class="ttl-gray pb-5">Más que una compañía, somos compañeros</h1>
        <div class="row py-5">
          <div class="col-lg-4">
            <div class="m-1">
              <div class="glass">
                <h6>
                  Lo que nos mueve es la honestidad,<br />la flexibilidad y la
                  efectividad
                </h6>
                <p>
                  Establecemos vínculos a largo plazo, para que cada uno sea
                  líder de su propio potencial ¡Llegamos para ayudar!
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="m-1">
              <div class="glass">
                <h6>Detrás de la cortina <br />tenemos todo lo necesario</h6>
                <p>
                  Brindamos estrategias y soluciones desarrolladas especialmente
                  para cada uno de nuestros clientes. Aunque, sobre todo,
                  aportamos calidez humana
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="m-1">
              <div class="glass">
                <h6>
                  Lo que te preocupa,<br />
                  nos ocupa
                </h6>
                <p>
                  Traemos a la mesa un equipo multidisciplinario y una variedad
                  de herramientas orientadas a conseguir resultados ¡Hay
                  equipo!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center pb-5">
          <a class="btn-submit" target="_blank" href="https://meetings.hubspot.com/guadalupe-cieri">
            <p class="mb-0 px-2">Ahora que nos conoces, queremos conocerte</p>
          </a>
        </div>
      </div>
    </div>
  </section>
  <div class="bg-gray">
    <div class="container">
      <h3 class="ttl-white text-center py-5 m-0">
        Las personas hacen goles, los equipos ganan partidos
      </h3>
    </div>
  </div>
  <section
    class="vh-100 pt-5 d-flex flex-column align-center justify-content-center"
  >
    <div class="container">
      <div class="scroller-absolute">
        <h2 class="f-ttl-slide">Somos un montón de&nbsp</h2>
        <div id="slider" class="slider-svg">
          <div class="slide-holder">
            <div class="slide">
              <h2 class="ttl-gradient-animation f-ttl-slide">Analistas</h2>
            </div>
            <div class="slide">
              <h2 class="ttl-gradient-animation f-ttl-slide">Diseñadores Gráficos</h2>
            </div>
            <div class="slide">
              <h2 class="ttl-gradient-animation f-ttl-slide">Redactores</h2>
            </div>
            <div class="slide">
              <h2 class="ttl-gradient-animation f-ttl-slide">Generadores de Demanda</h2>
            </div>
            <div class="slide">
              <h2 class="ttl-gradient-animation f-ttl-slide">Social Media Managers</h2>
            </div>
            <div class="slide">
              <h2 class="ttl-gradient-animation f-ttl-slide">Redactores</h2>
            </div>
            <div class="slide">
              <h2 class="ttl-gradient-animation f-ttl-slide">Diseñadores UX</h2>
            </div>
            <div class="slide">
              <h2 class="ttl-gradient-animation f-ttl-slide">Community Managers</h2>
            </div>
            <div class="slide">
              <h2 class="ttl-gradient-animation f-ttl-slide">Creativos</h2>
            </div>
            <div class="slide">
              <h2 class="ttl-gradient-animation f-ttl-slide">Project Leaders</h2>
            </div>
            <div class="slide">
              <h2 class="ttl-gradient-animation f-ttl-slide">Traductores</h2>
            </div>
            <div class="slide">
              <h2 class="ttl-gradient-animation f-ttl-slide">Desarrolladores</h2>
            </div>
            <div class="slide">
              <h2 class="ttl-gradient-animation f-ttl-slide">Expertos SEO</h2>
            </div>
            <div class="slide">
              <h2 class="ttl-gradient-animation f-ttl-slide">Analistas</h2>
            </div>
            <div class="slide">
              <h2 class="ttl-gradient-animation f-ttl-slide">Diseñadores Gráficos</h2>
            </div>
            <div class="slide">
              <h2 class="ttl-gradient-animation f-ttl-slide">Redactores</h2>
            </div>
            <div class="slide">
              <h2 class="ttl-gradient-animation f-ttl-slide">Generadores de Demanda</h2>
            </div>
            <div class="slide">
              <h2 class="ttl-gradient-animation f-ttl-slide">Social Media Managers</h2>
            </div>
            <div class="slide">
              <h2 class="ttl-gradient-animation f-ttl-slide">Redactores</h2>
            </div>
          </div>
        </div>
      </div>
      <h2 class="f-ttl-slide">listos para poner manos a la obra</h2>
    </div>
    <div class="bubble-wrap">
      <div class="bubbles"></div>
    </div>
  </section>

  <Form />
</template>

<script>
import Form from "@/components/Form";
export default {
  components: {
    Form,
  },
  mounted: function () {
    const SCROLL_SPEED = 0.3;
    const NOISE_SPEED = 0.004;
    const NOISE_AMOUNT = 5;
    const CANVAS_WIDTH = 2800;

    const bubblesEl = document.querySelector(".bubbles");
    const bubbleSpecs = [
      { s: 0.6, x: 1134, y: 45 },
      { s: 0.6, x: 1620, y: 271 },
      { s: 0.6, x: 1761, y: 372 },
      { s: 0.6, x: 2499, y: 79 },
      { s: 0.6, x: 2704, y: 334 },
      { s: 0.6, x: 2271, y: 356 },
      { s: 0.6, x: 795, y: 226 },
      { s: 0.6, x: 276, y: 256 },
      { s: 0.6, x: 1210, y: 365 },
      { s: 0.6, x: 444, y: 193 },
      { s: 0.6, x: 2545, y: 387 },
      { s: 0.8, x: 1303, y: 193 },
      { s: 0.8, x: 907, y: 88 },
      { s: 0.8, x: 633, y: 320 },
      { s: 0.8, x: 323, y: 60 },
      { s: 0.8, x: 129, y: 357 },
      { s: 0.8, x: 1440, y: 342 },
      { s: 0.8, x: 1929, y: 293 },
      { s: 0.8, x: 2135, y: 198 },
      { s: 0.8, x: 2276, y: 82 },
      { s: 0.8, x: 2654, y: 182 },
      { s: 0.8, x: 2783, y: 60 },
      { x: 1519, y: 118 },
      { x: 1071, y: 233 },
      { x: 1773, y: 148 },
      { x: 2098, y: 385 },
      { x: 2423, y: 244 },
      { x: 901, y: 385 },
      { x: 624, y: 111 },
      { x: 75, y: 103 },
      { x: 413, y: 367 },
      { x: 2895, y: 271 },
      { x: 1990, y: 75 },
    ];

    class Bubbles {
      constructor(specs) {
        this.bubbles = [];

        specs.forEach((spec, index) => {
          this.bubbles.push(new Bubble(index, spec));
        });

        requestAnimationFrame(this.update.bind(this));
      }

      update() {
        this.bubbles.forEach((bubble) => bubble.update());
        this.raf = requestAnimationFrame(this.update.bind(this));
      }
    }

    class Bubble {
      constructor(index, { x, y, s = 1 }) {
        this.index = index;
        this.x = x;
        this.y = y;
        this.scale = s;

        this.noiseSeedX = Math.floor(Math.random() * 64000);
        this.noiseSeedY = Math.floor(Math.random() * 64000);

        this.el = document.createElement("div");
        this.el.className = `bubble logo${this.index + 1}`;
        bubblesEl.appendChild(this.el);
      }

      update() {
        this.noiseSeedX += NOISE_SPEED;
        this.noiseSeedY += NOISE_SPEED;
        let randomX = noise.simplex2(this.noiseSeedX, 0);
        let randomY = noise.simplex2(this.noiseSeedY, 0);

        this.x -= SCROLL_SPEED;
        this.xWithNoise = this.x + randomX * NOISE_AMOUNT;
        this.yWithNoise = this.y + randomY * NOISE_AMOUNT;

        if (this.x < -200) {
          this.x = CANVAS_WIDTH;
        }

        this.el.style.transform = `translate(${this.xWithNoise}px, ${this.yWithNoise}px) scale(${this.scale})`;
      }
    }

    // For perlin noise
    noise.seed(Math.floor(Math.random() * 6000));

    const bubbles = new Bubbles(bubbleSpecs);
  },
};
</script>


<style>
.bg-gradient-triangle {
  background-image: url("../../assets/img/about/triangle_gradient.png");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 100vh;
}
.bg-gradient-arrows {
  background-image: url("../../assets/img/about/arrows_bg.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed, local;
}
.bg-ttl {
  background-image: url("../../assets/img/about/bg_quote.png");
  background-repeat: no-repeat;
  background-position: center 20%;
  background-attachment: scroll;
  display: flex;
  justify-content: center;
}
@media (max-width: 1000px) {
  .reverse {
    flex-direction: column-reverse;
  }
  .bg-gradient-gray img,
  .bg-gradient-gray h3,
  .bg-gradient-gray p {
    text-align: center;
  }
  .bg-ttl {
    background-size: contain;
  }
  .bg-gradient-arrows {
    background-size: 80vh;
    background-position: center 30%;
  }
  .bg-ttl img {
    position: relative;
    left: 5vw;
    width: 80vw;
  }
}

/********* slider*/
#slider {
  position: relative;
  top: 0;
  overflow: hidden;
  width: 50%;
}
@media(max-width: 1000px) {
  #slider {
    width: 100%;
    min-height: 50px;
  }
  .f-ttl-slide{
    font-size: 7vw;
  }
}
#slider .slide-holder {
  position: relative;
  left: 0;
}
.scroller-float #slider .slide {
  position: relative;
  top: 0;
  left: 0;
}
#slider.slider-svg .slide-holder .slide {
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: center;
}
#slider a,
#slider:hover a {
  display: block;
  height: 100%;
  margin: 0;
  padding: 0;
  border: none;
}
#slider .slide:hover {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  filter: gray;
  -webkit-filter: grayscale(100%);
  -ms-filter: â€œalpha(opacity=70) â€;
  zoom: 1;
  filter: alpha(opacity=70);
  -khtml-opacity: 0.7;
  opacity: 0.7;
  -moz-opacity: 0.7;
}
#slider img {
  width: 100%;
  height: auto;
}
/*SIZE*/
.slide-300x200 #slider,
.slide-300x200 #slider .slide {
  min-width: 30%;
  height: 80px;
  margin: 0 auto;
}
/*scroller-float*/
.scroller-float.scroller-20 #slider .slide-holder {
  width: 2100%;
}
.scroller-20.slide-300x200 #slider .slide-holder {
  -webkit-animation: scroller-float_scroller-20 20s infinite;
  animation: scroller-float_scroller-20 20s infinite;
}
.scroller-float #slider .slide {
  float: left;
}
.scroller-float #slider.slider-svg .slide-holder .slide {
  background-size: 50%;
}
@keyframes scroller-float_scroller-20 {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  5% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10% {
    -webkit-transform: translateX(-300px);
    transform: translateX(-300px);
  }
  10% {
    -webkit-transform: translateX(-300px);
    transform: translateX(-300px);
  }
  15% {
    -webkit-transform: translateX(-600px);
    transform: translateX(-600px);
  }
  15% {
    -webkit-transform: translateX(-600px);
    transform: translateX(-600px);
  }
  20% {
    -webkit-transform: translateX(-900px);
    transform: translateX(-900px);
  }
  20% {
    -webkit-transform: translateX(-900px);
    transform: translateX(-900px);
  }
  25% {
    -webkit-transform: translateX(-1200px);
    transform: translateX(-1200px);
  }
  25% {
    -webkit-transform: translateX(-1200px);
    transform: translateX(-1200px);
  }
  30% {
    -webkit-transform: translateX(-1500px);
    transform: translateX(-1500px);
  }
  30% {
    -webkit-transform: translateX(-1500px);
    transform: translateX(-1500px);
  }
  35% {
    -webkit-transform: translateX(-1800px);
    transform: translateX(-1800px);
  }
  35% {
    -webkit-transform: translateX(-1800px);
    transform: translateX(-1800px);
  }
  40% {
    -webkit-transform: translateX(-2100px);
    transform: translateX(-2100px);
  }
  40% {
    -webkit-transform: translateX(-2100px);
    transform: translateX(-2100px);
  }
  45% {
    -webkit-transform: translateX(-2400px);
    transform: translateX(-2400px);
  }
  45% {
    -webkit-transform: translateX(-2400px);
    transform: translateX(-2400px);
  }
  50% {
    -webkit-transform: translateX(-2700px);
    transform: translateX(-2700px);
  }
  50% {
    -webkit-transform: translateX(-2700px);
    transform: translateX(-2700px);
  }
  55% {
    -webkit-transform: translateX(-3000px);
    transform: translateX(-3000px);
  }
  55% {
    -webkit-transform: translateX(-3000px);
    transform: translateX(-3000px);
  }
  60% {
    -webkit-transform: translateX(-3300px);
    transform: translateX(-3300px);
  }
  60% {
    -webkit-transform: translateX(-3300px);
    transform: translateX(-3300px);
  }
  65% {
    -webkit-transform: translateX(-3600px);
    transform: translateX(-3600px);
  }
  65% {
    -webkit-transform: translateX(-3600px);
    transform: translateX(-3600px);
  }
  70% {
    -webkit-transform: translateX(-3900px);
    transform: translateX(-3900px);
  }
  70% {
    -webkit-transform: translateX(-3900px);
    transform: translateX(-3900px);
  }
  75% {
    -webkit-transform: translateX(-4200px);
    transform: translateX(-4200px);
  }
  75% {
    -webkit-transform: translateX(-4200px);
    transform: translateX(-4200px);
  }
  80% {
    -webkit-transform: translateX(-4500px);
    transform: translateX(-4500px);
  }
  80% {
    -webkit-transform: translateX(-4500px);
    transform: translateX(-4500px);
  }
  85% {
    -webkit-transform: translateX(-4800px);
    transform: translateX(-4800px);
  }
  85% {
    -webkit-transform: translateX(-4800px);
    transform: translateX(-4800px);
  }
  90% {
    -webkit-transform: translateX(-5100px);
    transform: translateX(-5100px);
  }
  90% {
    -webkit-transform: translateX(-5100px);
    transform: translateX(-5100px);
  }
  95% {
    -webkit-transform: translateX(-5400px);
    transform: translateX(-5400px);
  }
  95% {
    -webkit-transform: translateX(-5400px);
    transform: translateX(-5400px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
/*scroller-absolute*/
.scroller-absolute {
  display: flex;
  min-width: 90vw;
}

@media(max-width: 800px){
  .scroller-absolute {
    flex-direction: column;
  }
}

.scroller-absolute #slider .slide-holder {
  width: 100%;
}
.scroller-absolute #slider .slide {
  position: absolute;
  top: -200px;
}
.scroller-absolute #slider .slide-holder > div:nth-of-type(1) {
  animation: scroller-top1 ease 20s infinite;
}
.scroller-absolute #slider .slide-holder > div:nth-of-type(2) {
  animation: scroller-top2 ease 20s infinite;
}
.scroller-absolute #slider .slide-holder > div:nth-of-type(3) {
  animation: scroller-top3 ease 20s infinite;
}
.scroller-absolute #slider .slide-holder > div:nth-of-type(4) {
  animation: scroller-top4 ease 20s infinite;
}
.scroller-absolute #slider .slide-holder > div:nth-of-type(5) {
  animation: scroller-top5 ease 20s infinite;
}
.scroller-absolute #slider .slide-holder > div:nth-of-type(6) {
  animation: scroller-top6 ease 20s infinite;
}
.scroller-absolute #slider .slide-holder > div:nth-of-type(7) {
  animation: scroller-top7 ease 20s infinite;
}
.scroller-absolute #slider .slide-holder > div:nth-of-type(8) {
  animation: scroller-top8 ease 20s infinite;
}
.scroller-absolute #slider .slide-holder > div:nth-of-type(9) {
  animation: scroller-top9 ease 20s infinite;
}
.scroller-absolute #slider .slide-holder > div:nth-of-type(10) {
  animation: scroller-top10 ease 20s infinite;
}
.scroller-absolute #slider .slide-holder > div:nth-of-type(11) {
  animation: scroller-top11 ease 20s infinite;
}
.scroller-absolute #slider .slide-holder > div:nth-of-type(12) {
  animation: scroller-top12 ease 20s infinite;
}
.scroller-absolute #slider .slide-holder > div:nth-of-type(13) {
  animation: scroller-top13 ease 20s infinite;
}
.scroller-absolute #slider .slide-holder > div:nth-of-type(14) {
  animation: scroller-top14 ease 20s infinite;
}
.scroller-absolute #slider .slide-holder > div:nth-of-type(15) {
  animation: scroller-top15 ease 20s infinite;
}
.scroller-absolute #slider .slide-holder > div:nth-of-type(16) {
  animation: scroller-top16 ease 20s infinite;
}
.scroller-absolute #slider .slide-holder > div:nth-of-type(17) {
  animation: scroller-top17 ease 20s infinite;
}
.scroller-absolute #slider .slide-holder > div:nth-of-type(18) {
  animation: scroller-top18 ease 20s infinite;
}
.scroller-absolute #slider .slide-holder > div:nth-of-type(19) {
  animation: scroller-top19 ease 20s infinite;
}
.scroller-absolute #slider .slide-holder > div:nth-of-type(20) {
  animation: scroller-top20 ease 20s infinite;
}
@keyframes scroller-top1 {
  0% {
    top: -200px;
  }
  1% {
    top: 0;
  }
  4% {
    top: 0;
  }
  5% {
    top: 200px;
  }
  100% {
    top: 200px;
  }
}
@keyframes scroller-top2 {
  0% {
    top: -200px;
  }
  4% {
    top: -200px;
  }
  5% {
    top: 0;
  }
  9% {
    top: 0;
  }
  10% {
    top: 200px;
  }
  100% {
    top: 200px;
  }
}
@keyframes scroller-top3 {
  0% {
    top: -200px;
  }
  9% {
    top: -200px;
  }
  10% {
    top: 0;
  }
  14% {
    top: 0;
  }
  15% {
    top: 200px;
  }
  100% {
    top: 200px;
  }
}
@keyframes scroller-top4 {
  0% {
    top: -200px;
  }
  14% {
    top: -200px;
  }
  15% {
    top: 0;
  }
  19% {
    top: 0;
  }
  20% {
    top: 200px;
  }
  100% {
    top: 200px;
  }
}
@keyframes scroller-top5 {
  0% {
    top: -200px;
  }
  19% {
    top: -200px;
  }
  20% {
    top: 0;
  }
  24% {
    top: 0;
  }
  25% {
    top: 200px;
  }
  100% {
    top: 200px;
  }
}
@keyframes scroller-top6 {
  0% {
    top: -200px;
  }
  24% {
    top: -200px;
  }
  25% {
    top: 0;
  }
  29% {
    top: 0;
  }
  30% {
    top: 200px;
  }
  100% {
    top: 200px;
  }
}
@keyframes scroller-top7 {
  0% {
    top: -200px;
  }
  29% {
    top: -200px;
  }
  30% {
    top: 0;
  }
  34% {
    top: 0;
  }
  35% {
    top: 200px;
  }
  100% {
    top: 200px;
  }
}
@keyframes scroller-top8 {
  0% {
    top: -200px;
  }
  34% {
    top: -200px;
  }
  35% {
    top: 0;
  }
  39% {
    top: 0;
  }
  40% {
    top: 200px;
  }
  100% {
    top: 200px;
  }
}
@keyframes scroller-top9 {
  0% {
    top: -200px;
  }
  39% {
    top: -200px;
  }
  40% {
    top: 0;
  }
  44% {
    top: 0;
  }
  45% {
    top: 200px;
  }
  100% {
    top: 200px;
  }
}
@keyframes scroller-top10 {
  0% {
    top: -200px;
  }
  44% {
    top: -200px;
  }
  45% {
    top: 0;
  }
  49% {
    top: 0;
  }
  50% {
    top: 200px;
  }
  100% {
    top: 200px;
  }
}
@keyframes scroller-top11 {
  0% {
    top: -200px;
  }
  49% {
    top: -200px;
  }
  50% {
    top: 0;
  }
  54% {
    top: 0;
  }
  55% {
    top: 200px;
  }
  100% {
    top: 200px;
  }
}
@keyframes scroller-top12 {
  0% {
    top: -200px;
  }
  54% {
    top: -200px;
  }
  55% {
    top: 0;
  }
  59% {
    top: 0;
  }
  60% {
    top: 200px;
  }
  100% {
    top: 200px;
  }
}
@keyframes scroller-top13 {
  0% {
    top: -200px;
  }
  59% {
    top: -200px;
  }
  60% {
    top: 0;
  }
  64% {
    top: 0;
  }
  65% {
    top: 200px;
  }
  100% {
    top: 200px;
  }
}
@keyframes scroller-top14 {
  0% {
    top: -200px;
  }
  64% {
    top: -200px;
  }
  65% {
    top: 0;
  }
  69% {
    top: 0;
  }
  70% {
    top: 200px;
  }
  100% {
    top: 200px;
  }
}
@keyframes scroller-top15 {
  0% {
    top: -200px;
  }
  69% {
    top: -200px;
  }
  70% {
    top: 0;
  }
  74% {
    top: 0;
  }
  75% {
    top: 200px;
  }
  100% {
    top: 200px;
  }
}
@keyframes scroller-top16 {
  0% {
    top: -200px;
  }
  74% {
    top: -200px;
  }
  75% {
    top: 0;
  }
  79% {
    top: 0;
  }
  80% {
    top: 200px;
  }
  100% {
    top: 200px;
  }
}
@keyframes scroller-top17 {
  0% {
    top: -200px;
  }
  79% {
    top: -200px;
  }
  80% {
    top: 0;
  }
  84% {
    top: 0;
  }
  85% {
    top: 200px;
  }
  100% {
    top: 200px;
  }
}
@keyframes scroller-top18 {
  0% {
    top: -200px;
  }
  84% {
    top: -200px;
  }
  85% {
    top: 0;
  }
  89% {
    top: 0;
  }
  90% {
    top: 200px;
  }
  100% {
    top: 200px;
  }
}
@keyframes scroller-top19 {
  0% {
    top: -200px;
  }
  89% {
    top: -200px;
  }
  90% {
    top: 0;
  }
  94% {
    top: 0;
  }
  95% {
    top: 200px;
  }
  100% {
    top: 200px;
  }
}
@keyframes scroller-top20 {
  0% {
    top: -200px;
  }
  94% {
    top: -200px;
  }
  95% {
    top: 0;
  }
  99.5% {
    top: 0;
  }
  100% {
    top: 200px;
  }
}

.scroller-20.slide-300x200 #slider:hover .slide-holder,
.scroller-absolute #slider:hover .slide-holder > div {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}

.btn-submit {
  width: 35vw;
  height: auto;
  margin: 0 auto 0 0;
  padding: 5px 10px;
  font-weight: 900;
}
@media(max-width: 1080px){
  .btn-submit {
    width: 90vw;
  }
}

.glass {
  width: 100%;
  height: 18vh;
  position: relative;
  z-index: 1;
  background: inherit;
  overflow: hidden;
  padding: 1rem;
  border-radius: 5px;
}

@media(max-width: 1080px){
  .glass{
    height: auto;
  }
}

.glass p{
  line-height: 110%;;
}

.glass:before {
  content: "";
  position: absolute;
  background: inherit;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 300px 300px 500px rgba(255, 255, 255, 0.6);
  filter: blur(5px);
  margin: -20px;
}

@media (max-width: 800px) {
  .glass {
    margin: 5px 0;
  }
}
</style>