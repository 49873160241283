<template>
  <Header
    :titulo="tituloDiseno"
    :classColor="ttlColor"
    :parrafo="parrafoDiseno"
    :folder="carpeta"
    :name="nombreImg"
    :bgTriangle="bgTrianglewhite"
    :btnLinkHeader="btnLinkHeader"
    :btnText="btnText"
  />
  <section>
    <article class="py-5">
      <div class="container">
        <h3 class="text-center wow fadeInUp my-5">
          Existe un lugar para cada sitio
        </h3>
        <div class="row row-mobile justify-content-center">
          <CardHoverBg
            v-for="card in cards"
            :imgFront="card.imgFrontCard"
            :ttlCardFront="card.ttlCardFront"
            :imgBack="card.imgBackCard"
            :ttlCardBack="card.ttlBack"
          />
        </div>
        <div class="text-center my-4">
          <Button :buttonText="textBtn" :btnLink="btnLink" :class="btnBackground" />
        </div>
      </div>
    </article>
  </section>
  <!--
    <section>
    <article class="py-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 p-relative">
            <img style="position: absolute;bottom: 0;left: 0; height: 70vh;" src="@/assets/img/diseno/big-chevron.png" alt="">
            <img style="position: sticky;bottom: 0; left: 0; height: 70vh;" src="@/assets/img/diseno/computer.png" alt="">
            <img style="position: absolute;bottom: 0;right: 0; height: 20vh;" src="@/assets/img/diseno/tiny-chevron.png" alt="">
          </div>
          <div class="col-lg-6 my-auto">
            <h2 class="ttl-gray">Accesibilidad Web</h2>
            <p class="ttl-gray mt-4">
              Hoy, la mayoría de los sitios web presentan <strong>barreras de accesibilidad</strong> que afectan o imposibilitan su uso para muchas personas con dificultades visuales, 
              motrices, auditivas, cognitivas, neurológicas e intelectuales, entre otras. El momento para asegurarse que <strong>todos llegarán a tu sitio web,</strong> incluyendo personas de edad avanzada 
              o con algún tipo de discapacidad, es ahora.
            </p>
          </div>
        </div>
      </div>  
    </article>
    <article class="bg-gradient-static">
      <div class="bg-tiny-hand">
        <div class="container py-5">
          <h3 class="f-light ttl-white">Cuando unimos fuerzas con <span> <img src="@/assets/img/diseno/itps-tech.png" alt=""> </span>, pasar esa barrera es dar un salto clave a tan solo un click de distancia.</h3>
        </div>
      </div>
    </article>
  </section>
  -->
  <section class="bg-gray-arrow">
    <article class="pt-5">
      <div class="container">
        <h6 class="text-start">Casos de éxito</h6>
        <h1>DANONE | LA SERENISIMA</h1>
        <div class="row my-5 justify-content-center text-center">
          <div class="col-6 col-lg-3 my-auto">
            <img class="img-fluid m-3" style="height: 7vh" src="@/assets/img/logos/ActimelLogo.svg" alt="">
          </div>
          <div class="col-6 col-lg-3 my-auto ">
            <img class="img-fluid m-3" style="height: 10vh" src="@/assets/img/logos/SerenitoLogo.svg" alt="">
          </div>
          <div class="col-6 col-lg-3 my-auto">
            <img class="img-fluid m-3" style="height: 7vh" src="@/assets/img/logos/Yogurisimo.svg" alt="">
          </div>
          <div class="col-6 col-lg-3 my-auto">
            <img class="img-fluid m-3" style="height: 7vh" src="@/assets/img/logos/SerLogo.svg" alt="">
          </div>
        </div>
        <p class="text-left">
          El cliente nos convocó para llevar a cabo el rediseño y desarrollo de los sitios web para tres de sus marcas de yogurt: Actimel, Yogurísimo y Ser; y su línea de postres Serenito.
        </p>
        <p class="text-left">
          Para las primeras tres, tomamos la identidad visual de cada marca y con ello diseñamos bumpers, banners, slides y otros formatos digitales. A lo largo de tres meses de campaña, ajustamos sus tres landings e implementamos diseño animado y 3D.
        </p>
        <p class="text-left">
          En el caso de Serenito, hicimos el rediseño integral de su web y llevamos los nuevos personajes y productos de la nueva campaña al sitio. Además, brindamos servicios de Social Media Management por tres meses, donde cada posteo introducía a cada personaje, invitando a los usuarios a conocerlos mejor en su sitio web. 
        </p>
        <img class="img-fluid" src="@/assets/img/diseno/prints-danone.png" alt="">
      </div>
    </article>
  </section>
  <Form />
</template>
<script>
import "@/assets/css/styles.css";
import Header from "@/components/Header";
import QuoteBgGray from "@/components/QuoteBgGray";
import CardHoverBg from "@/components/CardHoverBg";
import Form from "@/components/Form";
import Button from "@/components/Button";
export default {
  components: {
    Header,
    QuoteBgGray,
    CardHoverBg,
    Form,
    Button
  },
  data() {
    return {
      tituloDiseno: "Donde las ideas y la realidad se encuentran",
      parrafoDiseno:
        "Llegamos para hacer lo imposible, posible. Con nuestros desarrollos, nos ocupamos de llevar la experiencia de tus clientes al siguiente nivel.",
      btnLinkHeader: "#contact",
      ttlColor: "ttl-gray",
      tituloSimple: "Influencers",
      textBtn: "¡Desarrollemos juntos!", 
      btnLink: "https://meetings.hubspot.com/guadalupe-cieri",
      parrafoSimple:
        "¿Qué tienen de especial? que ya tienen una comunidad creada en torno al interés y credibilidad de una persona que, con sus opiniones, puede influenciar las decisiones de su audiencia. Una recomendación es mucho más segura cuando viene de un amigo o alguien en quien confiamos ¿No es así? El influencer adecuado puede ser una especie de embajador para fidelizar el sentido de pertenencia del cliente con tu marca. En tiempos donde cada vez hay una mayor cantidad de contenidos promocionales, es más difícil para las marcas destacarse entre tal saturación de información. Es por eso que muchos de nuestros aliados encuentran su solución recurriendo a nuestros servicios de Influence Marketing para lograr incidir en la conducta de sus consumidores.",
        carpeta: 'diseno',
        nombreImg: 'computer',
        bgTrianglewhite: 'bg-triangle-white',
        btnText: "Cuéntanos tu idea más imposible",
        btnBackground: "btnAnimate",
      cards: [
        {
          imgFrontCard: "https://lareceda.sirv.com/Images/img-mktg/diseno/portal_websites.png",
          ttlCardFront: "Sitios web y portales",
          imgBackCard: "https://ucarecdn.com/24dacc44-81c6-4483-bd9e-261f688cd309/icon1.png",
          ttlBack:
            "Nos aseguramos de ofrecerle a tus usuarios un portal amigable y completo para tu contenido digital.",
        },
        {
          imgFrontCard: "https://lareceda.sirv.com/Images/img-mktg/diseno/brochure_catalog.png",
          ttlCardFront: "E-commerce",
          imgBackCard: "https://ucarecdn.com/8c3ccaf5-c58c-4210-8313-5200f0457c8d/icon2.png",
          ttlBack: "Vende tus productos o servicios en una plataforma propia y personalizada para tomar las riendas de tu negocio.",
        },
        {
          imgFrontCard: "https://lareceda.sirv.com/Images/img-mktg/diseno/educational_websites.png",
          ttlCardFront: "Posicionamiento SEO",
          imgBackCard: "https://ucarecdn.com/ee6f261f-1b11-419c-9847-31ab252d4a11/icon3.png",
          ttlBack:
            "Posiciona a tu compañía en lo más alto de los motores de búsqueda para aumentar tu visibilidad y tus ventas.",
        },
        {
          imgFrontCard: "https://lareceda.sirv.com/Images/img-mktg/diseno/business_websites.png",
          ttlCardFront: "Landing pages",
          imgBackCard: "https://ucarecdn.com/ed5df0fc-185a-484b-b1e3-5ab6983ca90b/icon4.png",
          ttlBack:
            "Convierte usuarios en clientes finales a través de landing pages claras y atractivas.",
        },
        {
          imgFrontCard: "https://lareceda.sirv.com/Images/img-mktg/diseno/brand_websites.png",
          ttlCardFront: "Plataformas eLearning",
          imgBackCard: "https://ucarecdn.com/9e436926-7b99-4066-ad66-3d688b15fa14/icon5.png",
          ttlBack:
            "Simplificamos el desafío que significa la educación a distancia con soluciones sencillas y entretenidas.",
        },
        {
          imgFrontCard: "https://lareceda.sirv.com/Images/img-mktg/diseno/ecommerce.png",
          ttlCardFront: "Webinars",
          imgBackCard: "https://ucarecdn.com/871acfd0-8ebf-4465-8a54-b8eee0126dc6/icon6.png",
          ttlBack:
            "Atrae nuevos leads con cursos, charlas y seminarios online.",
        },
      ],
    };
  },
  mounted(){
    new TypeIt("#ttl-home", {
        strings: this.tituloDiseno,
        speed: 50,
        waitUntilVisible: true,
    }).go();
  }
};
</script>

<style>
.bg-tiny-hand{
  background-image: url('../../assets/img/diseno/tiny-hands.png');
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
}

</style>