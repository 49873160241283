<template>
  <Header
    :titulo="tituloSocial"
    :classColor="ttlColor"
    :parrafo="parrafoSocial"
    :folder="carpeta"
    :name="nombreImg"
    :bgTriangle="bgTrianglewhite"
    :btnLinkHeader="btnLinkHeader"
    :btnText="btnText"
  />
  <section>
    <article>
      <QuoteBgGray :quote="quoteSocial" />
    </article>
  </section>
  <section>
    <article>
      <div class="container pb-5">
        <h1 class="text-center wow fadeInUp my-5">
          Comunicación
        </h1>
        <div class="row row-mobile justify-content-center">
          <CardHoverBg
            v-for="card in cardsOne"
            :imgFront="card.imgFrontCard"
            :ttlCardFront="card.ttlCardFront"
            :imgBack="card.imgBackCard"
            :ttlCardBack="card.ttlBack"
          />
        </div>
        <div class="text-center my-4">
          <Button :buttonText="textPartners" :btnLink="btnLink" :class="btnBackground"/>
        </div>
      </div>
    </article>
  </section>
  
  <section class="bg-gray-arrow">
    <article class="pt-5">
      <div class="container py-15">
        <h1 class="text-center">Caso de éxito</h1>
        <div class="row py-5 justify-content-center">
          <div class="col-lg-4">
            <img style="height: 20vh" src="@/assets/img/logos/LogoHuawei.svg" alt="">
            <p class="text-left mt-5 my-3">
              Ubicada en el puesto número 5 del mercado mundial por su market share de Internet As A Service (IaaS) durante 2020, <strong>Huawei Cloud</strong> es la marca que engloba todos los productos, servicios y soportes basados en la nube.
            </p>
            <p class="text-left">
              Durante más de ocho meses, la campaña combinó servicios de <strong>Social Media Management y Paid Media</strong>. Desarrollamos segmentaciones y estrategias de engagement, sumado a un aumento de notoriedad y tráfico para toda Latinoamérica. Generamos e implementamos una estrategia y agenda de contenidos, documentos on-demand, animaciones y presentaciones para clientes. Además de estos contenidos digitales, diseñamos piezas gráficas para cartelería en aeropuertos internacionales. 
            </p>
          </div>
          <div class="col-lg-1"></div>
          <div class="col-lg-7 p-relative">
            <img class="p-img-huawei" src="@/assets/img/social/cards.png" alt="">
          </div>
        </div>
        
      </div>
    </article>
  </section>
  <Form />
</template>
<script>
import "@/assets/css/styles.css";
import Header from "@/components/Header";
import QuoteBgGray from "@/components/QuoteBgGray";
import CardHoverBg from "@/components/CardHoverBg";
import SimpleText from "@/components/SimpleText";
import Form from "@/components/Form";
import Button from "@/components/Button";
export default {
  components: {
    Header,
    QuoteBgGray,
    CardHoverBg,
    SimpleText,
    Form,
    Button
  },
  data() {
    return {
      tituloSocial: "Hechos por y para las redes sociales",
      parrafoSocial:
        "Las redes logran un alcance exponencial que solamente el entorno digital puede ofrecer.",
      btnLinkHeader: "#contact",
      quoteSocial:
        "¿Estás aprovechando el poder de tus redes?",
      ttlColor: "ttl-white",
      carpeta: 'social',
      nombreImg: 'img_header', 
      bgTrianglewhite: 'bg-triangle-white',
      btnText: "¡Llevemos tus perfiles al infinito y más allá!",
      textPartners: "¿Quieres que todos te vean?",
      btnLink: "https://meetings.hubspot.com/guadalupe-cieri",
      btnBackground: "btnAnimate",
      cardsOne: [
        {
          imgFrontCard: "https://lareceda.sirv.com/Images/img-mktg/social/icon_2.png",
          ttlCardFront: "Social Listening",
          imgBackCard: "https://ucarecdn.com/62200461-da78-4b0c-9c61-8305f3c1e9b8/icon2c.png",
          ttlBack: "Escucha a tu audiencia y competidores para estar a la delantera.",
        },
        {
          imgFrontCard: "https://lareceda.sirv.com/Images/img-mktg/social/icon_1.png",
          ttlCardFront: "Estrategia en Redes Sociales",
          imgBackCard: "https://ucarecdn.com/4869a65e-590b-4868-bda0-25265301c89c/icon1c.png",
          ttlBack:
            "Diseñamos el plan de redes según tus necesidades, con objetivos claros y acciones medibles.",
        },
        {
          imgFrontCard: "https://lareceda.sirv.com/Images/img-mktg/social/icon_3.png",
          ttlCardFront: "Content Marketing",
          imgBackCard: "https://ucarecdn.com/c248a342-a248-4cbe-9f90-ff8d21a5fed1/icon3c.png",
          ttlBack:
            "El contenido correcto, en el momento indicado, para el segmento ideal.",
        },
        {
          imgFrontCard: "https://lareceda.sirv.com/Images/img-mktg/social/icon_4.png",
          ttlCardFront: "Community Management",
          imgBackCard: "https://ucarecdn.com/072cbec7-0a1a-40bd-aad7-44aa5e664094/icon4c.png",
          ttlBack:
            "Ejecutamos las estrategias, garantizamos que el plan se lleve a cabo para que tus objetivos se cumplan, e interactuamos con tu público objetivo para fidelizar una comunidad.",
        },
        {
          imgFrontCard: "https://lareceda.sirv.com/Images/img-mktg/social/icon_5.png",
          ttlCardFront: "Paid Media",
          imgBackCard: "https://ucarecdn.com/eded5908-73a0-4811-b4b4-6e160d10bdbc/icon5c.png",
          ttlBack:
            "Creamos campañas publicitarias exitosas (data driven) con inversión en medios digitales para lograr tus objetivos de alcance, engagement y/o venta.",
        },
      ],
    };
  },
  mounted(){
    new TypeIt("#ttl-home", {
        strings: this.tituloSocial,
        speed: 50,
        waitUntilVisible: true,
    }).go();
  }
};
</script>

<style scoped>
.bg-tiny-hand{
  background-image: url('../../assets/img/diseno/tiny-hands.png');
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
}

.py-15{
  padding: 15vh 0;
}
@media(max-width: 999px){
  .p-img-huawei{
    height: auto;
    width: 90%;
  }
  .bg-gray-arrow .row{
    width: 99%;
    text-align: center;
  }

}
@media(min-width: 1000px){
  .p-img-huawei{
    position: sticky; 
    top: 10vh; 
    height: 90vh;
  }
}

</style>