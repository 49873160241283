<template>
  <a
    :href="btnLink"
    class="custom-btn btn-15"
    :class="childclass" 
    >{{ buttonText }}</a
  >
</template>

<script>
export default {
  name: "Button",
  props: ["buttonText", "btnLink", "childclass"],
};
</script>

<style>
.custom-btn {
  width: auto;
  min-height: 40px;
  color: #fff;
  border-radius: 40px;
  padding: 10px 25px;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  outline: none;
  font-family: "Montserrat-Light";
  font-weight: 900;
}
.bg-gradient-anim a {
  background: #fff;
  color: var(--gray);
  font-size: 25px;
}
@media(max-width: 1080px){
  .bg-gradient-anim a{
    font-size: 15px;
    max-width: 75vw;
  }
}
.btnAnimate {
  background: linear-gradient(
    266deg,
    #0ad493,
    #1384c1,
    #0ad493,
    #1384c1,
    #0ad493
  );
  background-size: 500% 500%;
  font-size: 20px;
}
@media(max-width: 1080px){
  .btnAnimate{
    font-size: 15px;
    width: 80vw;
  }
}
/* 15 */
.btn-15 {
  -webkit-animation: bg-gradient 5s ease-in-out infinite;
  -moz-animation: bg-gradient 5s ease-in-out infinite;
  animation: bg-gradient 5s ease-in-out infinite;
  border: none;
  z-index: 1;
}
.btn-15:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background-color: #0ad493;
  border-radius: 40px;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease;
}
.btn-15:hover {
  color: #fff;
}
.btn-15:hover:after {
  left: 0;
  width: 100%;
}
.btn-15:active {
  top: 2px;
}
</style>