<template>
  <Header
    :titulo="tituloGeneracion"
    :classColor="ttlColor"
    :parrafo="parrafoGeneracion"
    :folder="carpeta"
    :name="nombreImg"
    :bgTriangle="bgTrianglewhite"
    :btnLinkHeader="btnLinkHeader"
    :btnText="btnText"
  />

  <section>
    <article>
      <div class="container py-5">
        <h1 class="text-center wow fadeInUp my-5">¿Cómo lo hacemos?</h1>
        <div class="row row-mobile justify-content-center">
          <CardHoverBg
            v-for="card in cards"
            :imgFront="card.imgFrontCard"
            :ttlCardFront="card.ttlCardFront"
            :imgBack="card.imgBackCard"
            :ttlCardBack="card.ttlBack"
          />
        </div>
        <div class="text-center mt-5">
          <Button :buttonText="textPartners" :btnLink="btnLink" :class="btnBackground" />
        </div>
      </div>
    </article>
  </section>

  <section class="bg-gray-arrow">
    <article class="pt-5 p-relative">
      <div class="container py-15">
        <h1 class="text-center">Casos de éxito</h1>
        <div class="row py-5">
          <div class="col-lg-4">
            <img
              class="m-3"
              src="@/assets/img/logos/CognitusLogo.svg"
              alt=""
              style="height: 100px"
            />
            <p class="my-3">
              Cognitus Consulting es una consultora multinacional IT especializada en soluciones SAP y productos para múltiples industrias. Cuenta con más de 14 oficinas alrededor del mundo y está presente en cinco continentes. Nuestro equipo de Generación de Demanda está en contacto diario con sus líderes en Estados Unidos, India y los Países Bajos, con los cuales aseguró cinco leads en los primeros 10 días de junio 2021, para soluciones de Aeroespacio y Defensa, entre otras.
            </p>
            <p class="my-3">
              Cada unidad dentro del equipo modifica sus secuencias de venta y canales de comunicación según la solución, industria y cliente final en cuestión para satisfacer las necesidades dentro de Cognitus, antes de alcanzar el lead deseado.
            </p>
            <div class="d-flex mt-5">
              <img
                class="h-chevron"
                src="@/assets/img/content-mktg/Assets-03.svg"
                alt=""
              />
              <div class="d-flex">
                <h1 class="ttl-gray m-0 mx-1">05</h1>
                <div class="my-auto mx-2">
                  <h5 class="text-uppercase m-0">leads</h5>
                  <p class="m-0">en 10 días</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-1"></div>
          <div class="col-lg-4">
            <img
              class="m-3"
              src="@/assets/img/logos/EAILogo.svg"
              alt=""
              style="height: 100px"
            />
            <p class="my-3">
              La misma metodología fue aplicada con el Enterprise Agility Institute (EAI), aunque con un único equipo de Generación de Demanda trabajando en paralelo al de Social Media Management. Pese a ser un equipo reducido, se concretaron 26 leads en apenas dos meses de campaña.
            </p>
            <div class="d-flex mt-5">
              <img
                class="h-chevron"
                src="@/assets/img/content-mktg/Assets-03.svg"
                alt=""
              />
              <div class="d-flex">
                <h1 class="ttl-gray mx-1">26</h1>
                <div class="my-auto mx-2">
                  <h5 class="text-uppercase m-0">leads</h5>
                  <p class="m-0">en 60 días</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img class="img-embudo" src="@/assets/img/generacion/embudo.png" alt="" />
    </article>
  </section>
  <Form />
</template>
<script>
import "@/assets/css/styles.css";
import Header from "@/components/Header";
import QuoteBgGray from "@/components/QuoteBgGray";
import CardHoverBg from "@/components/CardHoverBg";
import Form from "@/components/Form";
import Button from "@/components/Button";
export default {
  components: {
    Header,
    QuoteBgGray,
    CardHoverBg,
    Form,
    Button
  },
  data() {
    return {
      tituloGeneracion: "Todos para uno",
      parrafoGeneracion:
        "Lejos de ser un proceso lineal, la Generación de Demanda tiene un enfoque holístico, estratégico y escalable donde tus éxitos también son los nuestros.",
      btnLinkHeader: "#contact",
      btnText: "¿Estás listo para alcanzar nuevas fronteras?",
      ttlColor: "ttl-white",
      tituloSimple: "Influencers",
      carpeta: "generacion",
      nombreImg: "img_header",
      bgTrianglewhite: "bg-triangle-white",
      textPartners: "Reservá tu lugar en un mundo de oportunidades.",
      btnLink: "https://meetings.hubspot.com/guadalupe-cieri",
      btnBackground: "btnAnimate",
      cards: [
        {
          imgFrontCard:
            "https://lareceda.sirv.com/Images/img-mktg/generacion/icon_1.png",
          ttlCardFront: "Integrada",
          imgBackCard:
            "https://ucarecdn.com/bef35ae9-70a0-4ab2-afcd-6a043dca8cae/icon_1d.png",
          ttlBack:
            "Preparados para todo terreno: Combinando diferentes estrategias y técnicas de marketing por varios canales, nuestro equipo de GD crea una base holística para generar contactos y convertirlos en clientes fidelizados.",
        },
        {
          imgFrontCard:
            "https://lareceda.sirv.com/Images/img-mktg/generacion/icon_2.png",
          ttlCardFront: "Cuantificable",
          imgBackCard:
            "https://ucarecdn.com/6e08139b-1b4f-495a-81ab-b08fd81a9713/icon_2d.png",
          ttlBack:
            "En cantidad, con calidad: Nos enfocamos en los datos, realizamos pruebas y optimizamos la estrategia continuamente en base a los resultados procedentes de cada secuencia y etapa del funnel de ventas.",
        },
        {
          imgFrontCard:
            "https://lareceda.sirv.com/Images/img-mktg/generacion/icon_4.png",
          ttlCardFront: "Ventas",
          imgBackCard:
            "https://ucarecdn.com/b360d6b8-aeb6-4e4f-bb56-24ac9e8234e6/icon_4d.png",
          ttlBack:
            "Cazadores de tesoros: La Generación de Demanda tiene un impacto directo sobre las ventas. Por esta razón, nos integramos con los esfuerzos del área comercial de tu empresa para conseguir los resultados que buscan.",
        },
        {
          imgFrontCard:
            "https://lareceda.sirv.com/Images/img-mktg/generacion/icon_3.png",
          ttlCardFront: "Duradera",
          imgBackCard:
            "https://ucarecdn.com/c6e33caf-b6b2-4721-a126-55edfc5dfdfe/icon_3d.png",
          ttlBack:
            "¡Que no se corte!: Todas las medidas que implementamos se orientan hacia la creación de relaciones a largo plazo con clientes y/o empresas.",
        },
      ],
    };
  },
  mounted() {
    new TypeIt("#ttl-home", {
      strings: this.tituloGeneracion,
      speed: 50,
      waitUntilVisible: true,
    }).go();
  },
};
</script>

<style scoped>
.bg-tiny-hand {
  background-image: url("../../assets/img/diseno/tiny-hands.png");
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
}

.py-15 {
  padding: 15vh 0;
}

.h-chevron {
  height: 6vh;
  margin: auto 0;
}
@media (max-width: 999px) {
  .img-embudo {
    width: auto;
    height: 30vh;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .bg-gray-arrow .row {
    width: 99%;
    text-align: center;
  }
}

@media (min-width: 1000px) {
  .img-embudo {
    position: absolute;
    right: 0px;
    bottom: 0px;
    height: 80vh;
  }
}
</style>