<template>
  <Header
    :titulo="tituloBranding"
    :classColor="ttlColor"
    :parrafo="parrafoDiseno"
    :folder="carpeta"
    :name="nombreImg"
    :bgTriangle="bgTrianglewhite"
    :btnLinkHeader="btnLinkHeader"
    :btnText="btnText"
  />
  <section>
    <article>
      <div class="container pb-5">
        <div class="py-5">
          <h6 class="text-start mb-0">
            Comunicación
          </h6>
          <h1 class="wow fadeInUp">Lo que digo</h1>
        </div>
        <div class="row row-mobile justify-content-center">
          <CardHoverBg
            v-for="card in cardsOne"
            :imgFront="card.imgFrontCard"
            :ttlCardFront="card.ttlCardFront"
            :imgBack="card.imgBackCard"
            :ttlCardBack="card.ttlBack"
          />
        </div>
      </div>
    </article>
    <article>
      <QuoteBgGray :quote="quoteBrandingSecond" />
    </article>
  </section>
  <section>
    <article>
      <div class="container pb-5">
        <div class="py-5">
          <h6 class="text-start mb-0">
            Branding
          </h6>
          <h1 class="wow fadeInUp">Lo que hago</h1>
        </div>
        <div class="row row-mobile justify-content-center">
          <CardHoverBg
            v-for="card in cardsTwo"
            :imgFront="card.imgFrontCard"
            :ttlCardFront="card.ttlCardFront"
            :imgBack="card.imgBackCard"
            :ttlCardBack="card.ttlBack"
          />
        </div>
      </div>
      
    </article>
    <div class="text-center my-4 pb-5">
        <Button :buttonText="textPartners" :btnLink="btnLink" :class="btnBackground"/>
      </div>
  </section>
  
  <section class="bg-gray-arrow-branding">
    <article class="pt-5">
      <div class="container py-15">
        <h1 class="text-center">Caso de éxito</h1>
        <div class="row py-5 justify-content-center">
          <div class="col-lg-4">
            <img class="img-fluid mb-5 p-mobile" src="@/assets/img/branding/AssetsBranding-17.svg" alt="">
            <p class="text-left my-3">
              Flex es una cadena de <strong>coworking y depósitos</strong> con distintas sucursales en Argentina. Sin embargo, cuando Flex se acercó a nosotros, su propuesta todavía estaba por darse a conocer.
            </p>
            <p class="text-left">
              Tras varias sesiones de brainstorming e incorporando el feedback del cliente, comenzamos con el <strong>diseño de packaging y visuales</strong> para la página web. Una vez que las piezas fueron aprobadas y comunicaban la identidad propia del cliente, la pieza final para completar la imagen de Flex fue la creación de un <strong>nuevo logotipo</strong>.
            </p>
          </div>
          <div class="col-lg-1"></div>
          <div class="col-lg-7 p-relative">
            <img class="p-computer-flex" src="@/assets/img/branding/computers.png" alt="">
          </div>
        </div>
        
      </div>
    </article>
  </section>
  <Form />
</template>
<script>
import "@/assets/css/styles.css";
import Header from "@/components/Header";
import QuoteBgGray from "@/components/QuoteBgGray";
import CardHoverBg from "@/components/CardHoverBg";
import Form from "@/components/Form";
import Button from '@/components/Button';

export default {
  components: {
    Header,
    QuoteBgGray,
    CardHoverBg,
    Form,
    Button
  },
  data() {
    return {
      tituloBranding: "Todos tenemos una historia para contar",
      parrafoDiseno:
        "Encuentra tu identidad, muestra tus valores y logra una conexión real con tu público para que vuelvan una y otra vez.",
      btnLinkHeader: "#contact",
      btnText: "¿Quién quieres ser?",
      quoteBrandingSecond:
        "Toda marca tiene una historia. Toda historia tiene un público. Nos gustaría contar la tuya",
      ttlColor: "ttl-white",
      tituloSimple: "Influencers",
      parrafoSimple:
        "¿Qué tienen de especial? que ya tienen una comunidad creada en torno al interés y credibilidad de una persona que, con sus opiniones, puede influenciar las decisiones de su audiencia. Una recomendación es mucho más segura cuando viene de un amigo o alguien en quien confiamos ¿No es así? El influencer adecuado puede ser una especie de embajador para fidelizar el sentido de pertenencia del cliente con tu marca. En tiempos donde cada vez hay una mayor cantidad de contenidos promocionales, es más difícil para las marcas destacarse entre tal saturación de información. Es por eso que muchos de nuestros aliados encuentran su solución recurriendo a nuestros servicios de Influence Marketing para lograr incidir en la conducta de sus consumidores.",
      carpeta: 'branding',
      nombreImg: 'AssetsBranding-02UNIR',
      bgTrianglewhite: 'bg-triangle-white',
      textPartners: "¡Queremos escuchar tu historia!",
      btnLink: "https://meetings.hubspot.com/guadalupe-cieri",
      btnBackground: "btnAnimate",
      cardsOne: [
        {
          imgFrontCard: "https://lareceda.sirv.com/Images/img-mktg/branding/AssetsBranding-09.svg",
          ttlCardFront: "Social Media Management",
          imgBackCard: "https://ucarecdn.com/ab39697d-c0e6-4bcc-a204-1978cc9f6fd2/icon7b.png",
          ttlBack:
            "Contactanos",
        },
        {
          imgFrontCard: "https://lareceda.sirv.com/Images/img-mktg/branding/AssetsBranding-03.svg",
          ttlCardFront: "Redacción Creativa",
          imgBackCard: "https://ucarecdn.com/6d1bb5dd-86fa-4e64-b4ee-eab64f164cc6/icon1b.png",
          ttlBack: "Contactanos",
        },
        {
          imgFrontCard: "https://lareceda.sirv.com/Images/img-mktg/branding/AssetsBranding-07.svg",
          ttlCardFront: "C.R.M.",
          imgBackCard: "https://ucarecdn.com/d84e3e53-5f5f-4c25-ba96-32e16a4c8d7d/icon5b.png",
          ttlBack:
            "Contactanos",
        },
        {
          imgFrontCard: "https://lareceda.sirv.com/Images/img-mktg/branding/AssetsBranding-04.svg",
          ttlCardFront: "Diseño gráfico",
          imgBackCard: "https://ucarecdn.com/618ea7fb-c162-435a-aafb-3aad90e892b9/icon2b.png",
          ttlBack:
            "Contactanos",
        },
        {
          imgFrontCard: "https://lareceda.sirv.com/Images/img-mktg/branding/AssetsBranding-08.svg",
          ttlCardFront: "Facebook Ads e Instagram Ads",
          imgBackCard: "https://ucarecdn.com/ca36004b-a244-49c5-8b06-53ddb370716b/icon6b.png",
          ttlBack:
            "Contactanos",
        },
        {
          imgFrontCard: "https://lareceda.sirv.com/Images/img-mktg/branding/AssetsBranding-05.svg",
          ttlCardFront: "Producción Audiovisual",
          imgBackCard: "https://ucarecdn.com/60280f68-63c6-43ea-8d70-84491dbc862f/icon3b.png",
          ttlBack:
            "Contactanos",
        },
        {
          imgFrontCard: "https://lareceda.sirv.com/Images/img-mktg/branding/AssetsBranding-06.svg",
          ttlCardFront: "Desarrollo Web y Apps",
          imgBackCard: "https://ucarecdn.com/6c3736be-55dc-46ae-8125-e9b8159f9c9b/icon4b.png",
          ttlBack:
            "Contactanos",
        },
      ],
      cardsTwo: [
        {
          imgFrontCard: "https://lareceda.sirv.com/Images/img-mktg/branding/AssetsBranding-10.svg",
          ttlCardFront: "Servicio al cliente",
          imgBackCard: "https://ucarecdn.com/7b95218f-84c9-4ce7-98d5-0b5d19172257/icon8b.png",
          ttlBack:
            "Contactanos",
        },
        {
          imgFrontCard: "https://lareceda.sirv.com/Images/img-mktg/branding/AssetsBranding-11.svg",
          ttlCardFront: "Brand Awareness",
          imgBackCard: "https://ucarecdn.com/4aed908e-14c3-42a2-bc92-5586f1969143/icon9b.png",
          ttlBack: "Contactanos",
        },
        {
          imgFrontCard: "https://lareceda.sirv.com/Images/img-mktg/branding/AssetsBranding-16.svg",
          ttlCardFront: "Retención/Loyalty",
          imgBackCard: "https://ucarecdn.com/7a4e085f-24e4-41c6-a81f-ada34b1407d2/icon14b.png",
          ttlBack:
            "Contactanos",
        },
        {
          imgFrontCard: "https://lareceda.sirv.com/Images/img-mktg/branding/AssetsBranding-12.svg",
          ttlCardFront: "Conversión y enriquecimiento de leads",
          imgBackCard: "https://ucarecdn.com/00894a40-a3b5-4f73-8a7e-a5673f8238f7/icon10b.png",
          ttlBack:
            "Contactanos",
        },
        {
          imgFrontCard: "https://lareceda.sirv.com/Images/img-mktg/branding/AssetsBranding-15.svg",
          ttlCardFront: "Aumento de ventas",
          imgBackCard: "https://ucarecdn.com/c9dedf8f-1c0f-4fa1-911f-d07203f6f92c/icon13b.png",
          ttlBack:
            "Contactanos",
        },
        {
          imgFrontCard: "https://lareceda.sirv.com/Images/img-mktg/branding/AssetsBranding-13.svg",
          ttlCardFront: "Conversión de clientes",
          imgBackCard: "https://ucarecdn.com/f1ea4ed5-46bd-4bee-b779-96a2a65cb69f/icon11b.png",
          ttlBack:
            "Contactanos",
        },
        {
          imgFrontCard: "https://lareceda.sirv.com/Images/img-mktg/branding/AssetsBranding-14.svg",
          ttlCardFront: "Suscriptores leales",
          imgBackCard: "https://ucarecdn.com/9c75ed3d-49c6-4fab-afaa-ca9566d66a56/icon12b.png",
          ttlBack:
            "Contactanos",
        },
      ],
    };
  },
  mounted () {
      new TypeIt("#ttl-home", {
        strings: this.tituloBranding,
        speed: 50,
        waitUntilVisible: true,
      }).go();
  }
};
</script>

<style scoped>
.bg-tiny-hand{
  background-image: url('../../assets/img/diseno/tiny-hands.png');
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
}

.bg-gray-arrow-branding{
    height: 160vh;
    background-color: #DBDADB;
    background-image: url('../../assets/img/branding/bg_success.png');
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: cover;
}
.py-15{
  padding: 15vh 0;
}
.p-computer-flex{
  position: sticky; 
  top: 10vh; 
  height: 60vh;
}

@media(max-width: 1000px){
  .p-mobile{
    padding: 0 2rem;
  }
  .p-computer-flex{
    display: none;
  }
  .bg-gray-arrow-branding{
    background-position: bottom center;
  }
}
</style>