<template>
  <Header
    :titulo="tituloContent"
    :classColor="ttlColor"
    :parrafo="parrafoContent"
    :folder="carpeta"
    :name="nombreImg"
    :bgTriangle="bgTrianglewhite"
    :btnLinkHeader="btnLinkHeader"
    :btnText="btnText"
  />

  <section>
    <article>
      <div class="container py-5">
        <h1 class="text-center wow fadeInUp my-5">
          Atraemos a tu público con...
        </h1>
        <div class="row row-mobile justify-content-center">
          <CardHoverBg
            v-for="card in cards"
            :imgFront="card.imgFrontCard"
            :ttlCardFront="card.ttlCardFront"
            :imgBack="card.imgBackCard"
            :ttlCardBack="card.ttlBack"
          />
        </div>
      </div>
    </article>
  </section>

  <section>
    <article>
      <QuoteBgGray :quote="quoteContent" />
    </article>
  </section>

  <section class="vh-100">
    <article>
      <div class="container pt-5">
        <div class="row">
          <div class="col-lg-4 p-relative">
            <img
              class="my-4 wow fadeInUp img-fluid"
              src="@/assets/img/content-mktg/Assets-13.png"
              alt=""
            />
            <img
              class="avion-1 my-4 wow fadeInUp"
              src="@/assets/img/content-mktg/Assets-15.png"
              alt=""
            />
            <img
              class="avion-2 my-4 wow fadeInUp"
              src="@/assets/img/content-mktg/Assets-14.png"
              alt=""
            />
            <img
              class="avion-3 my-4 wow fadeInUp"
              src="@/assets/img/content-mktg/Assets-16.png"
              alt=""
            />
          </div>
          <div class="col-lg-8 my-auto">
            <h3>Creamos contenido con un objetivo</h3>
            <div class="row mt-5">
              <div class="col-lg-6">
                <div class="d-flex align-start magic-hover magic-hover__square">
                  <img
                    class="icon-plus-content wow fadeInUp"
                    src="@/assets/img/content-mktg/Assets-04.svg"
                    alt=""
                  />
                  <p>
                    <strong>Atraen tu clientes potenciales</strong> de otros
                    canales y mercados.
                  </p>
                </div>
                <div class="d-flex align-start magic-hover magic-hover__square">
                  <img
                    class="icon-plus-content wow fadeInUp"
                    src="@/assets/img/content-mktg/Assets-04.svg"
                    alt=""
                  />
                  <p>
                    Ayudan a conocer los
                    <strong>intereses de tu público</strong>.
                  </p>
                </div>
                <div class="d-flex align-start magic-hover magic-hover__square">
                  <img
                    class="icon-plus-content wow fadeInUp"
                    src="@/assets/img/content-mktg/Assets-04.svg"
                    alt=""
                  />
                  <p>
                    <strong
                      >Aumentan la confianza, empatía y credibilidad</strong
                    >
                    con ellos.
                  </p>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="d-flex align-start magic-hover magic-hover__square">
                  <img
                    class="icon-plus-content wow fadeInUp"
                    src="@/assets/img/content-mktg/Assets-04.svg"
                    alt=""
                  />
                  <p>
                    <strong>Atraen tus clientes potenciales</strong> de otros
                    canales y mercados.
                  </p>
                </div>
                <div class="d-flex align-start magic-hover magic-hover__square">
                  <img
                    class="icon-plus-content wow fadeInUp"
                    src="@/assets/img/content-mktg/Assets-04.svg"
                    alt=""
                  />
                  <p>
                    Genera <strong>grandes resultados</strong> con
                    <strong>pequeños presupuestos.</strong>
                  </p>
                </div>
                <div class="d-flex align-start magic-hover magic-hover__square">
                  <img
                    class="icon-plus-content wow fadeInUp"
                    src="@/assets/img/content-mktg/Assets-04.svg"
                    alt=""
                  />
                  <p>
                    <strong>Incrementan</strong> tu tasa de
                    <strong>conversión.</strong>
                  </p>
                </div>
                <div class="d-flex align-start magic-hover magic-hover__square">
                  <img
                    class="icon-plus-content wow fadeInUp"
                    src="@/assets/img/content-mktg/Assets-04.svg"
                    alt=""
                  />
                  <p>
                    Fomentan las <strong>ventas</strong> de manera más
                    <strong>orgánica</strong> que la pauta.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center mb-4">
          <Button :buttonText="textPartners" :btnLink="btnLink"  :class="btnBackground" />
        </div>
      </div>
    </article>
  </section>

  <section class="bg-gray-arrow">
    <article class="pt-5">
      <div class="container py-15">
        <h1 class="text-center">Caso de éxito</h1>
        <div class="row py-5 justify-content-center">
          <div class="text-left col-lg-4">
            <img
              class="p-5"
              src="@/assets/img/content-mktg/Assets-17.png"
              alt=""
            />
            <p class="my-3">
              Vital de <strong>Nutricia Bagó</strong> es una de las marcas
              líderes de leche en polvo en Argentina y sus productos se
              comercializan en supermercados, farmacias y tiendas digitales a lo
              largo de todo el país.
            </p>
            <p class="my-3">
              El acercamiento con nosotros ocurrió previo al lanzamiento de
              campaña de su nueva leche, Vital 4 Fibra Plus, la cual necesitaba
              un <strong>concepto</strong>, una
              <strong>identidad visual</strong>, un <strong>propósito</strong> y
              una manera efectiva de <strong>comunicarse</strong> con su público
              objetivo.
            </p>
            <p class="my-3">
              Luego de varias sesiones de brainstorming, nuestro equipo terminó
              desarrollando el concepto de campaña con un armado de
              <strong>Key Visuals</strong> integral para redes sociales a través
              de contenidos como videos, bumpers, carruseles, banners e
              imágenes.
            </p>
            <!-- 
              <div class="d-flex mt-5">
              <img class="h-chevron" src="@/assets/img/content-mktg/Assets-03.svg" alt="" />
              <div>
                <h1 class="ttl-gray m-0">11.3<small>M</small></h1>
                <p class="m-0">Alcance Total</p>
              </div>
            </div>
            <div class="d-flex my-4">
              <img class="h-chevron" src="@/assets/img/content-mktg/Assets-03.svg" alt="" />
              <div>
                <h1 class="ttl-gray m-0">75<small>%</small></h1>
                <p class="m-0">Cobertura</p>
              </div>
            </div>
            <div class="d-flex my-3">
              <img class="h-chevron" src="@/assets/img/content-mktg/Assets-03.svg" alt="" />
              <div>
                <h1 class="ttl-gray m-0">VA +80<small>%</small></h1>
                <p class="m-0">en banners</p>
              </div>
            </div>
            -->
          </div>
          <div class="col-lg-8 p-relative">
            <img
              class="img-fluid"
              src="@/assets/img/content-mktg/Assets-18.png"
              alt=""
            />
            <!-- 
                <div class="my-3">
                <img class="h-chevron" src="@/assets/img/content-mktg/Assets-03.svg" alt="" />
                <div>
                  <h1 class="ttl-gray m-0"><small>$</small>0,02</h1>
                  <p class="m-0">de CUU optimizado en piezas pautadas</p>
                </div>
              </div>
              <div class="my-3">
                <img class="h-chevron" src="@/assets/img/content-mktg/Assets-03.svg" alt="" />
                <div>
                  <h1 class="ttl-gray m-0">
                    <small>VTR</small>+90<small>%</small>
                  </h1>
                  <p class="m-0">en video bumpers de Youtube</p>
                </div>
              </div>
              -->
          </div>
        </div>
      </div>
    </article>
  </section>
  <Form />
</template>
<script>
import "@/assets/css/styles.css";
import Header from "@/components/Header";
import QuoteBgGray from "@/components/QuoteBgGray";
import CardHoverBg from "@/components/CardHoverBg";
import Form from "@/components/Form";
import Button from "@/components/Button";
export default {
  components: {
    Header,
    QuoteBgGray,
    CardHoverBg,
    Form,
    Button,
  },
  data() {
    return {
      tituloContent: "El contenido es lo que importa",
      parrafoContent:
        "Al compartir tus valores, propósito y trabajo del día a día podrás conectar con tu audiencia de forma directa y personalizada.",
      btnLinkHeader: "#contact",
      btnText: "¿Qué te gustaría mostrar?",
      quoteBrandingSecond:
        "Toda marca tiene una historia. Toda historia tiene un público. Nos gustaría contar la tuya.",
      ttlColor: "ttl-white",
      tituloSimple: "Influencers",
      parrafoSimple:
        "¿Qué tienen de especial? que ya tienen una comunidad creada en torno al interés y credibilidad de una persona que, con sus opiniones, puede influenciar las decisiones de su audiencia. Una recomendación es mucho más segura cuando viene de un amigo o alguien en quien confiamos ¿No es así? El influencer adecuado puede ser una especie de embajador para fidelizar el sentido de pertenencia del cliente con tu marca. En tiempos donde cada vez hay una mayor cantidad de contenidos promocionales, es más difícil para las marcas destacarse entre tal saturación de información. Es por eso que muchos de nuestros aliados encuentran su solución recurriendo a nuestros servicios de Influence Marketing para lograr incidir en la conducta de sus consumidores.",
      carpeta: "content-mktg",
      nombreImg: "Assets-02",
      bgTrianglewhite: "bg-triangle-white",
      textPartners: "Comencemos el makeover de tus redes hoy",
      btnLink: "https://meetings.hubspot.com/guadalupe-cieri",
      btnBackground: "btnAnimate",
      cards: [
        {
          imgFrontCard:
            "https://lareceda.sirv.com/Images/img-mktg/content-mktg/Assets-06.svg",
          ttlCardFront: "Diseño",
          imgBackCard:
            "https://ucarecdn.com/618ea7fb-c162-435a-aafb-3aad90e892b9/icon2b.png",
          ttlBack: "Contactanos",
        },
        {
          imgFrontCard:
            "https://ucarecdn.com/c3409b6b-05b4-419b-a8d3-f1a877e36ebb/Assets11.png",
          ttlCardFront: "Fotografía",
          imgBackCard:
            "https://ucarecdn.com/e33caa95-83cb-4155-809b-9e772ad4f1cc/Assets11copia.png",
          ttlBack: "Contactanos",
        },
        {
          imgFrontCard:
            "https://ucarecdn.com/d49087ba-beef-4aa5-a695-64b55f63b581/Assets08.png",
          ttlCardFront: "Redacción",
          imgBackCard:
            "https://ucarecdn.com/0558d3de-4077-41e2-8a0f-67ee24d71756/Assets08copia.png",
          ttlBack: "Contactanos",
        },
        {
          imgFrontCard:
            "https://ucarecdn.com/1d498c98-fb54-4918-96b2-c5685dcde1fb/Assets09.png",
          ttlCardFront: "Ilustración",
          imgBackCard:
            "https://ucarecdn.com/58a3498d-85d2-4ca9-8704-e3721a689742/Assets09copia.png",
          ttlBack: "Contactanos",
        },
        {
          imgFrontCard:
            "https://ucarecdn.com/06656328-cd5d-4bb0-b284-0ff7d32d525d/Assets12.png",
          ttlCardFront: "Diseño audiovisual",
          imgBackCard:
            "https://ucarecdn.com/59a0dbde-832e-411d-b760-588236fbb741/Assets12copia.png",
          ttlBack: "Contactanos",
        },
        {
          imgFrontCard:
            "https://ucarecdn.com/5105418a-9bb2-4c7a-85a7-39048f5c3f82/Assets07.png",
          ttlCardFront: "Motion graphics",
          imgBackCard:
            "https://ucarecdn.com/a23ce0f2-658e-4799-9d67-a667026b3a59/Assets07copia.png",
          ttlBack: "Contactanos",
        },
        {
          imgFrontCard:
            "https://ucarecdn.com/87f0aa83-f849-43ea-9c72-c3b5531bd943/Assets10.png",
          ttlCardFront: "Edición de video",
          imgBackCard:
            "https://ucarecdn.com/d6dd03f0-9850-4c59-95be-8794f87cc11b/Assets10copia.png",
          ttlBack: "Contactanos",
        },
      ],
    };
  },
  mounted() {
    new TypeIt("#ttl-home", {
      strings: this.tituloContent,
      speed: 50,
      waitUntilVisible: true,
    }).go();
  },
};
</script>

<style scoped>
.bg-tiny-hand {
  background-image: url("../../assets/img/diseno/tiny-hands.png");
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
}

.py-15 {
  padding: 15vh 0;
}

.h-chevron {
  height: 6vh;
  margin: auto 0;
}
</style>