<template>
  <Header
    :titulo="tituloPaid"
    :classColor="ttlColor"
    :parrafo="parrafoPaid"
    :folder="carpeta"
    :name="nombreImg"
    :bgTriangle="bgTrianglewhite"
    :btnLinkHeader="btnLinkHeader"
    :btnText="btnText"
  />
  <section>
    <article>
      <div class="container pb-5">
        <h1 class="text-center my-5">
          Descubrí todo el potencial de las redes sociales
        </h1>
        <div class="row">
          <div class="col-lg-4">
            <ul class="list-inline mt-5">
              <li>. Aumento de tráfico a su sitio web o perfil de redes sociales</li>
              <li>. Posicionamiento de tu marca en motores de búsqueda</li>
              <li>. Segmentación y Retargeting</li>
              <li>. Analíticas adaptables a tus necesidades y objetivos</li>
              <li>. Campañas de Branding</li>
              <li>. Campañas de Performance</li>
              <li>. Campañas de Conversión</li>
            </ul>
          </div>
          <div class="col-lg-8 my-auto">
            <div class="row text-center my-5">
              <div class="col-6 col-lg-4 my-3">
                <img class="img-fluid" src="@/assets/img/logos/ig.png" alt="" />
              </div>
              <div class="col-6 col-lg-4 my-3 my-auto">
                <img class="img-fluid px-5" src="@/assets/img/logos/google.png" alt="" />
              </div>
              <div class="col-6 col-lg-4 my-3">
                <img class="img-fluid p-3" src="@/assets/img/logos/tw.png" alt="" />
              </div>
              <div class="col-6 col-lg-4 my-3 my-auto">
                <img class="img-fluid px-5" src="@/assets/img/logos/yt.png" alt="" />
              </div>
              <div class="col-6 col-lg-4 my-3">
                <img class="img-fluid " src="@/assets/img/logos/fb.png" alt="" />
              </div>
              <div class="col-6 col-lg-4 my-3 my-auto">
                <img class="img-fluid px-5" src="@/assets/img/logos/ln.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  </section>
  <QuoteBgGray :quote="quotePaid" />
  <section>
    <article>
      <div class="container py-5">
        <div class="row">
          <div class="col-lg-6">
            <small class="f-light">Remarketing</small>
            <h1 class="m-0">No dejes ir a tu cliente ideal</h1>
            <p class="mt-3">
              Contanos cuál es <strong>tu público objetivo</strong> y nos
              encargamos de segmentarlo por edad, sexo, ubicación geográfica,
              intereses y demás filtros a tu disposición.
            </p>
            <p>
              Una vez que aquellos usuarios
              <strong>ya ingresaron a tu web</strong>, podemos hacer que
              <strong>vuelvan a verte</strong> mientras navegan en otros sitios,
              redes o plataformas mediante una
              <strong>campaña de display</strong> en Google Ads.
            </p>
          </div>
        </div>
      </div>
      
    </article>
    <article class="bg-gradient-anim" >
      <div class="container py-5">
        <h2 class="text-center ttl-white">Somos rápidos, pero vamos paso a paso...</h2>
        <div class="row row-mobile justify-content-center mt-5">
          <CardHoverBg
            v-for="card in cards"
            :imgFront="card.imgFrontCard"
            :ttlCardFront="card.ttlCardFront"
            :imgBack="card.imgBackCard"
            :ttlCardBack="card.ttlBack"
          />
        </div>
        <div class="text-center mt-5">
          <Button :buttonText="textPartners" :btnLink="btnLink" :class="btnBackground" />
        </div>
      </div>
    </article>
  </section>
  <Form class="mt-5" />
</template>
<script>
import "@/assets/css/styles.css";
import Header from "@/components/Header";
import QuoteBgGray from "@/components/QuoteBgGray";
import SimpleText from "@/components/SimpleText";
import CardHoverBg from "@/components/CardHoverBg";
import Form from "@/components/Form";
import Button from "@/components/Button";
export default {
  components: {
    Header,
    QuoteBgGray,
    SimpleText,
    CardHoverBg,
    Form,
    Button,
  },
  data() {
    return {
      tituloPaid: "Una ventaja para tu contenido",
      parrafoPaid:
        "Una campaña de Paid Media es el empujón para llevar tu marca a los ojos de un público segmentado a tu medida y bajo tus propios criterios.",
      btnLinkHeader: "#contact",
      quotePaid: "Comencemos una campaña.",
      btnText: "Que tu contenido llegue a multitudes",
      textPartners: "¡Comencemos una campaña juntos!",
      btnLink: "https://meetings.hubspot.com/guadalupe-cieri",
      ttlColor: "ttl-white",
      ttlRemarketing: "Remarketing",
      parrafoRemarketing:
        "Contanos cuál es tu público objetivo y nos encargamos de segmentarlo por edad, sexo, ubicación geográfica, intereses y demás filtros a tu disposición. Una vez que aquellos usuarios ya ingresaron a tu web, podemos hacer que vuelvan a verte mientras navegan en otros sitios, redes o plataformas mediante una campaña de display en Google Ads.",
      carpeta: "paid-media",
      nombreImg: "img_header",
      bgTrianglewhite: "bg-triangle-white",
      btnBackground: "btnAnimate",
      cards: [
        {
          imgFrontCard:
            "https://lareceda.sirv.com/Images/img-mktg/paid/icon_1.png",
          ttlCardFront: "Medición de Datos",
          imgBackCard:
            "https://lareceda.sirv.com/Images/img-mktg/paid/icon_1.png",
          ttlBack: "Contactanos",
        },
        {
          imgFrontCard:
            "https://lareceda.sirv.com/Images/img-mktg/paid/icon_2.png",
          ttlCardFront: "Análisis de Tráfico",
          imgBackCard:
            "https://lareceda.sirv.com/Images/img-mktg/paid/icon_2.png",
          ttlBack: "Contactanos",
        },
        {
          imgFrontCard:
            "https://lareceda.sirv.com/Images/img-mktg/paid/icon_3.png",
          ttlCardFront: "Reporte de Paid Media",
          imgBackCard:
            "https://lareceda.sirv.com/Images/img-mktg/paid/icon_3.png",
          ttlBack: "Contactanos",
        },
      ],
    };
  },
  mounted() {
    new TypeIt("#ttl-home", {
      strings: this.tituloPaid,
      speed: 50,
      waitUntilVisible: true,
    }).go();
  },
};
</script>