<template>
  <!-- TODO: Modificar el col en mobile -->
  <div class="col text-center p-2" >
    <div class="box-item card">
      <div class="flip-box">
        <div
          class="flip-box-front text-center filter card-front"
          :style="{ backgroundImage: 'url(' + imgFront + ')' }"
        >
          <div class="inner color-white">
            <h6 class="flip-box-header ttl-gray"> {{ ttlCardFront }} </h6>
          </div>
        </div>
        <div
          class="flip-box-back text-center card-back"
          :style="{ backgroundImage: 'url(' + imgBack + ')' }"
        >
          <div class="inner bg_text color-white">
            <h6 class="flip-box-header-bg ttl-gray f-light">
              {{ ttlCardBack }}
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardHoverBg",
  props: [
    'ttlCardFront',
    'imgFront',
    'imgBack',
    'ttlCardBack',
  ],
};
</script>

<style scoped>
.box-item {
  background-color: #fff;
  position: relative;
  -webkit-backface-visibility: hidden;
  display: flex;
  justify-content: center;
}

.card {
  background-color: #fff;
  background-image: linear-gradient(
      0deg,
      transparent 24%,
      #acacac1b 25%,
      #acacac1b 26%,
      transparent 27%,
      transparent 74%,
      #acacac1b 75%,
      #acacac1b 76%,
      transparent 77%,
      transparent
    ),
    linear-gradient(
      90deg,
      transparent 24%,
      #acacac1b 25%,
      #acacac1b 26%,
      transparent 27%,
      transparent 74%,
      #acacac1b 75%,
      #acacac1b 76%,
      transparent 77%,
      transparent
    );
  height: 30vh;
  max-width: 19.5vw;
  background-size: 40px 40px;
  transition: 0.3s all ease-in-out;
  color: var(--gray);
  border-radius: 0;
  margin: 0 auto;
}


.flip-box-header {
  position: relative;
  bottom: 10vh;
  padding: 0 50px;
}

.flip-box {
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  perspective: 1000px;
  -webkit-perspective: 1000px;
  height: 20vh;
  max-width: 20vw;
}

@media(max-width: 1080px){
  .card,
  .flip-box{
    max-width: 100%;
  }
  .flip-box{
    height: 20vh;
  }
  .flip-box-header{
    bottom: 10vh;
  }
}


.flip-box-front,
.flip-box-back {
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  height: 20vh;
  -ms-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -webkit-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-front {
  -ms-transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.flip-box:hover .flip-box-header{
  bottom: 0;
  font-size: 0px;
}
.flip-box:hover .flip-box-front {
  -ms-transform: rotateY(-180deg);
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: .5s all ease-in-out;
}

.flip-box-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -ms-transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-back {
  -ms-transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.flip-box .inner {
  position: absolute;
  left: 0;
  width: 100%;
  outline: 1px solid transparent;
  -webkit-perspective: inherit;
  perspective: inherit;
  z-index: 2;
  transform: translateY(-50%) translateZ(60px) scale(0.94);
  -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
  -ms-transform: translateY(-50%) translateZ(60px) scale(0.94);
  top: 50%;
}

.flip-box-img {
  margin-top: 25px;
  height: 3vh;
}

.flip-box-button {
  background-color: var(--gray);
  color: var(--white);
  padding: 15px 20px;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
}
.flip-box-header-bg{
    width: 80%;
    margin: auto;
    font-weight: 900;
    font-size: 2vh;
}
@media(max-width: 1000px){
  .flip-box-header-bg{
    font-size: 15px;
  }
}
.card-front {
  background-size: 13.5vh;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
}
.card-back {
  background-size: 13.5vh;
  background-color: white;
  background-repeat: no-repeat;
}
.bg_text{
  background-size: cover;
  background-image: linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,1));
  color: var(--gray);
  padding: 20px 10px;
  border-radius: 10px;
}
@media (min-width: 1080px) {
  .col{
    flex: 1 0 25%;
  }
}

</style>