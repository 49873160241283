<template>
  <Header
    :titulo="tituloCustomer"
    :classColor="ttlColor"
    :parrafo="parrafoCustomer"
    :class="classCustomer"
    :folder="carpeta"
    :name="nombreImg"
    :bgTriangle="bgTrianglewhite"
    :url="urlTest"
    :btnLinkHeader="btnLinkHeader"
    :btnText="btnText"
  />
  <section class="py-5">
    <article>
      <header class="container">
        <h1 class="text-center wow fadeInUp">Una experiencia hecha a medida</h1>
      </header>
      <div class="container py-5">
        <div class="row row-mobile justify-content-center">
          <CardHoverBg
            v-for="card in cards"
            :imgFront="card.imgFrontCard"
            :ttlCardFront="card.ttlCardFront"
            :imgBack="card.imgBackCard"
            :ttlCardBack="card.ttlBack"
          />
        </div>
        
        <div class="text-center mt-5">
          <Button :buttonText="textCx" :btnLink="btnLink" :class="btnBackground" />
        </div>
      </div>
    </article>
  </section>
  <section class="bg-gray-arrow">
    <article class="py-5">
      <header>
        <h1 class="text-center">Caso de éxito</h1>
      </header>
      <div class="container py-5">
        <div class="row">
          <div class="col-lg-4 py-5">
            <img
              class="my-4 magic-hover magic-hover__square wow fadeInUp"
              src="../../assets/img/content-mktg/Assets-17.png"
              alt="Logo Vital"
            />
            <p class="ttl-gray">
              Bagóvit, marca líder mundial en cremas y productos de cuidado corporal, quería lograr que sus usuarios pudieran asesorarse de manera online sobre la variedad de productos ofrecidos.
            </p>
            <p class="ttl-gray">
              En lugar de contratar asistentes virtuales que operen dentro de ciertos horarios como habían sugerido, les propusimos desarrollar un chatbot customizable. Esto posibilitó el ahorro de tiempo de implementación contra el de capacitación, la reducción de costos de mantenimiento frente a honorarios, y una atención 24/7. Trabajamos con múltiples dermatólogos y especialistas, nutriendo al bot de conocimiento profesional previo para que tenga una respuesta a cada consulta. En el año 2020, cuando la atención presencial se encontraba limitada, se realizaron más de 50.000 consultas dermatológicas virtuales.
            </p>
            <p class="ttl-gray">
              Finalmente, lo que comenzó como una campaña de apenas dos meses continuó por más de un año con resultados sumamente positivos tanto para el cliente como para su público.
            </p>
          </div>
          <div class="col-lg-8 p-relative">
            <div class="p-sticky">
              <img
                class="wow fadeInUp img-fluid"
                src="../../assets/img/content-mktg/Assets-18.png"
                alt=""
              />
              <p class="text-center ttl-gray">
                Lo que comenzó como una campaña de apenas dos meses continuó por
                más de un año y con resultados positivos tanto para el cliente
                como para su público.
              </p>
            </div>
          </div>
        </div>
      </div>
    </article>
  </section>
  <Form />
</template>

<script>
import "@/assets/css/cx/style.css";
import "@/assets/css/styles.css";
import Header from "@/components/Header";
import Button from "@/components/Button";
import QuoteBgGray from "@/components/QuoteBgGray";
import CardHoverBg from "@/components/CardHoverBg";
import Form from "@/components/Form";
export default {
  components: {
    Header,
    QuoteBgGray,
    CardHoverBg,
    Form,
    Button
  },
  data() {
    return {
      tituloCustomer: "Convierte curiosos en clientes",
      btnLinkHeader: "#contact",
      parrafoCustomer:
        "Una experiencia de clientes personalizada e integrada con la identidad y el propósito de su marca es fundamental para diferenciarse de la competencia.",
      carpeta: "customer",
      bgTrianglewhite: "bg-triangle-white",
      nombreImg: "girl_customer",
      ttlColor: "ttl-gray",
      btnText: "¿Listo para crear una experiencia única?",
      textCx: "Agendá el comienzo de una experiencia única.",
      btnLink: "https://meetings.hubspot.com/guadalupe-cieri",
      btnBackground: "btnAnimate",
      cards: [
        {
          imgFrontCard:
            "https://ucarecdn.com/26ce0460-087c-44a8-a1e2-d62844ce7fbb/hand.png",
          ttlCardFront: "Reputación online",
          imgBackCard:
            "https://ucarecdn.com/4de88b07-ea11-423b-a2f9-dbdffd995ac4/handreverse.png",
          ttlBack: "Análisis de la situación actual de tu compañía y la competencia, seguido del diseño de una estrategia centrada en cuidar el equity de tu marca.",
        },
        {
          imgFrontCard:
            "https://ucarecdn.com/3c2ad71a-fd15-41f5-850e-9d9b02a9f769/cloud.png",
          ttlCardFront: "Big Data & Analytics",
          imgBackCard:
            "https://ucarecdn.com/40e81fcd-2f20-4873-90e5-270c199e45cd/cloudreverse.png",
          ttlBack: "Comprender, definir y segmentar a su Buyer Persona en base a investigaciones, analíticas y elaboración de bases de datos hipersegmentadas para maximizar ventas.",
        },
        {
          imgFrontCard:
            "https://ucarecdn.com/d49087ba-beef-4aa5-a695-64b55f63b581/Assets08.png",
          ttlCardFront: "Implementación de bots con inteligencia artificial",
          imgBackCard:
            "https://ucarecdn.com/0558d3de-4077-41e2-8a0f-67ee24d71756/Assets08copia.png",
          ttlBack: "Optimización de los canales de atención al cliente 24/7, automatizaciones y estrategias de mensajería para ahorrar tiempo y costos.",
        }
      ],
    };
  },
  mounted() {
    new TypeIt("#ttl-home", {
      strings: this.tituloCustomer,
      speed: 50,
      waitUntilVisible: true,
    }).go();
  },
};
</script>

<style>
.bg-triangle-white {
  background-image: url("../../assets/img/arrow_white.png");
  background-size: 90vh;
  height: 100vh;
  background-position: bottom right;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1000px) {
  .bg-triangle-white {
    background-position: bottom right;
    background-size: 10vh;
  }
}
.p-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

</style>