<template>
  <section
    class="
      vh-100
      bg-gradient-anim
      d-flex
      flex-column
      align-center
      justify-content-center
      p-relative
    "
  >
    <div :class="bgTriangle">
      <article :class="classArrow">
        <div class="container">
          <div class="row">
            <div class="col-lg-7 pt-5">
              <a class="ttl-black magic-hover magic-hover__square">
                <h1
                  id="ttl-home"
                  :class="classColor"
                  class="wow fadeInUp p-ttl-header"
                ></h1>
              </a>
              <p class="ttl-white mt-4 wow fadeInUp" data-aos="fade-up">
                {{ parrafo }}
              </p>
              <Button :buttonText="btnText"  :btnLink="btnLinkHeader" :class="btnHeader" />
            </div>
            <div class="col-lg-5"></div>
          </div>
        </div>
      </article>
      <div class="triangle"></div>
      <img
        class="banana hallucination-effect d-none-img-header"
        id="tilt"
        :src="require(`@/assets/img/${folder}/${name}.png`)"
        alt=""
      />
      <img class="arrow-1" src="@/assets/img/home/arrow-1.png" alt="" />
      <img class="arrow-2" src="@/assets/img/home/arrow-2.png" alt="" />
      <img class="arrow-3" src="@/assets/img/home/arrow-3.png" alt="" />
    </div>
  </section>
</template>

<script>
import Button from "@/components/Button";
export default {
  name: "Header",
  props: [
    "parrafo",
    "image",
    "classArrow",
    "classColor",
    "folder",
    "name",
    "bgTriangle",
    "btnText",
    "btnLinkHeader",
    "btnHeader"
  ],
  components: {
    Button
  },
};
</script>

<style>
.bg-triangle-gray p,
.bg-triangle-white p{
  font-size: 2vw;
  line-height: 1.1;
}

.p-ttl-header {
  padding: 0;
  font-size: 4vw;
}

@media(max-width: 1080px){
  .d-none-img-header {
    display: none;
  }
  .p-ttl-header {
    padding: 0 0 0 0;
    font-size: 6vh;
  }
  .bg-triangle-gray p,
  .bg-triangle-white p{
    font-size: 20px;
  }
}
</style>