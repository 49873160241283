import { createRouter, createWebHistory } from 'vue-router'
import AboutUs from '@/modules/pages/AboutUs';
import Branding from '@/modules/pages/Branding';
import ContentMarketing from '@/modules/pages/ContentMarketing';
import CustomerExperience from '@/modules/pages/CustomerExperience';
import DemandGeneration from '@/modules/pages/DemandGeneration';
import DisenoDesarrollo from '@/modules/pages/DisenoDesarrollo';
import Home from '@/modules/pages/Home';
import InfluenceMarketing from '@/modules/pages/InfluenceMarketing';
import PaidMedia from '@/modules/pages/PaidMedia';
import SocialMedia from '@/modules/pages/SocialMedia';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/quienes-somos',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/branding',
    name: 'Branding',
    component: Branding
  },
  {
    path: '/content-marketing',
    name: 'ContentMarketing',
    component: ContentMarketing
  },
  {
    path: '/customer-experience',
    name: 'CustomerExperience',
    component: CustomerExperience
  },
  {
    path: '/demand-generation',
    name: 'DemandGeneration',
    component: DemandGeneration
  },
  {
    path: '/diseno-desarrollo',
    name: 'DisenoDesarrollo',
    component: DisenoDesarrollo
  },
  {
    path: '/influence-marketing',
    name: 'InfluenceMarketing',
    component: InfluenceMarketing
  },
  {
    path: '/paid-media',
    name: 'PaidMedia',
    component: PaidMedia
  },
  {
    path: '/social-media',
    name: 'SocialMedia',
    component: SocialMedia
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
