<template>
  <Header
    :titulo="tituloInfluence"
    :classColor="ttlColor"
    :parrafo="parrafoInfluence"
    :folder="carpeta"
    :name="nombreImg"
    :bgTriangle="bgTrianglewhite"
    :btnLinkHeader="btnLinkHeader"
    :btnText="btnText"
  />
  <section>
    <div class="container py-5">
      <div class="row">
        <div class="col-lg-6">
          <small class="f-light">Influencers</small>
          <h1 class="m-0">
            Por la alfombra roja
          </h1>
          <p class="mt-3">
            Que tu marca se una a las estrellas más brillantes. Los influencers
            pueden ser los aliados ideales para destacar una empresa en el
            entorno digital.
          </p>
        </div>
      </div>
    </div>
    <article class="pt-5 bg-gradient-anim">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 ttl-white wow fadeInUp text-start py-5">
            <h1 class="mb-0 ttl-white">
              KOL
              <small class="f-light">(Key Opinion Leaders)</small>
            </h1>
            <h2 class="f-light mb-0 ttl-white">Bajo el reflector</h2>
            <p class="ttl-white mt-2">
              Los KOLs son expertos en la materia de una industria o sector
              específico. Además de aportar su experiencia y conocimiento,
              también cuentan con contactos influyentes, expertos,
              organizaciones, y otros líderes de opinión digital para aumentar
              el alcance de los seguidores a su alrededor.
            </p>
          </div>
          <div class="col-lg-2"></div>
          <div class="col-lg-4 p-relative">
            <img
              style="
                position: absolute;
                height: 30vh;
                bottom: 12vh;
                right: 200px;
              "
              class="hide-mobile"
              src="../../assets/img/influence/girl-1.png"
              alt=""
            />
            <img
              class="hide-mobile"
              style="position: absolute; height: 30vh; bottom: 2vh; right: 0"
              src="../../assets/img/influence/girl-2.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </article>
    <article>
      <h3 class="text-center wow fadeInUp my-5">En ITPS podemos ayudarte a:</h3>
      <div class="container">
        <div class="row row-mobile justify-content-center">
          <CardHoverBg
            v-for="card in cards"
            :imgFront="card.imgFrontCard"
            :ttlCardFront="card.ttlCardFront"
            :imgBack="card.imgBackCard"
            :ttlCardBack="card.ttlBack"
          />
        </div>
      </div>
    </article>
  </section>
  <Form class="mt-5" />
</template>
<script>
import "@/assets/css/cx/style.css";
import "@/assets/css/styles.css";
import Header from "@/components/Header";
import QuoteBgGray from "@/components/QuoteBgGray";
import SimpleText from "@/components/SimpleText";
import CardHoverBg from "@/components/CardHoverBg";
import Form from "@/components/Form";
export default {
  components: {
    Header,
    QuoteBgGray,
    SimpleText,
    CardHoverBg,
    Form,
  },
  data() {
    return {
      tituloInfluence: "A mayor influencia, mayor impacto",
      parrafoInfluence:
        "Potenciamos marcas trabajando con personas de relevancia. Sumar a los KOLs e influencers correctos a la estrategia permite a las empresas estar un paso adelante en el alcance al público.",
      quoteInfluence: "¿A quién querés influenciar?",
      ttlColor: "ttl-white",
      tituloSimple: "Influencers Por la alfombra roja",
      parrafoSimple:
        "Que tu marca se una a las estrellas más brillantes. Los influencers pueden ser los aliados ideales para destacar una empresa en el entorno digital.",
      carpeta: "influence",
      nombreImg: "img-hero-croco",
      bgTrianglewhite: "bg-triangle-white",
      btnText: "Que la cara de tu compañía suene conocida",
      btnLinkHeader: "#contact",
      cards: [
        {
          imgFrontCard:
            "https://lareceda.sirv.com/Images/img-mktg/influence/icon-1.png",
          ttlCardFront: "Estrategia",
          imgBackCard:
            "https://ucarecdn.com/89334660-5093-4156-9fd8-caf5e838f73b/icon1.png",
          ttlBack:
            "Alineamos la estrategia ideal para tu compañía al trabajar con influencers/KOLs",
        },
        {
          imgFrontCard:
            "https://lareceda.sirv.com/Images/img-mktg/influence/icon-2.png",
          ttlCardFront: "Selección",
          imgBackCard:
            "https://ucarecdn.com/0fd90e0c-5b4c-4087-b6a0-fea165807da2/icon2.png",
          ttlBack: "Buscamos los influencers/KOLs más adecuados",
        },
        {
          imgFrontCard:
            "https://lareceda.sirv.com/Images/img-mktg/influence/icon-3.png",
          ttlCardFront: "Contenidos",
          imgBackCard:
            "https://ucarecdn.com/cda0dcb3-2341-4c8e-98db-fc837f8479aa/icon3.png",
          ttlBack:
            "Desarrollamos e implementamos contenidos en el corto, mediano y largo plazo.",
        },
        {
          imgFrontCard:
            "https://lareceda.sirv.com/Images/img-mktg/influence/icon-4.png",
          ttlCardFront: "Capacitación",
          imgBackCard:
            "https://ucarecdn.com/8834c183-1cdd-4066-bf10-280422343c45/icon4.png",
          ttlBack:
            "Implementamos una inducción para convertir a los influencers/KOLs en reales embajadores de marca.",
        },
        {
          imgFrontCard:
            "https://lareceda.sirv.com/Images/img-mktg/influence/icon-5.png",
          ttlCardFront: "Seguimiento",
          imgBackCard:
            "https://ucarecdn.com/408c8847-657e-4bba-aff8-bf4613531230/icon5.png",
          ttlBack:
            "Realizamos un seguimiento para medir el impacto de las estrategias y ajustarlas.",
        },
      ],
    };
  },
  mounted() {
    new TypeIt("#ttl-home", {
      strings: this.tituloInfluence,
      speed: 50,
      waitUntilVisible: true,
    }).go();
  },
};
</script>

<style>
.p-dino-header {
  height: 80vh;
  position: relative;
  top: 10vh;
}
.icon-hero-1 {
  position: absolute;
  left: 18vw;
  top: 20vh;
  height: 20vh;
  animation: float 3s ease-in-out infinite;
}
.icon-hero-2 {
  position: absolute;
  left: 3vw;
  top: 30vh;
  height: 15vh;
  animation: float 4s ease-in-out infinite;
}
.icon-hero-3 {
  position: absolute;
  height: 20vh;
  bottom: 0;
  left: 13vw;
  animation: float 2.5s ease-in-out infinite;
}
@media (max-width: 1000px) {
  .hide-mobile {
    display: none;
  }
}
small {
  font-size: 20px;
}

</style>