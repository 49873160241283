<template>
  <footer id="contact" class="bg_gray_form">
    <div class="container">
      <div class="row">
        <div class="col-lg-7 d-flex flex-column justify-content-center">
          <h2 class="ttl-gray">
            Seamos creativos
            juntos.
          </h2>
          <div className="row pt-5">
            <div className="col-lg-3">
              <h5>Unites States</h5>
              <ul className="list-inline">
                <li><a href="https://goo.gl/maps/boDfzp8Y4TmW8kTG6" target="_blank">3390 Mary St #116,</a></li>
                <li>Coconut Grove, FL 33133</li>
                <li><a href="tel: +13057331969" target="_blank">+13057331969</a></li>
                <li><a href="mailto: usa@itps.one" target="_blank">usa@itps.one</a></li>
              </ul>
            </div>
            <div className="col-lg-3">
              <h5>México</h5>
              <ul className="list-inline">
                <li><a href="https://goo.gl/maps/2yC1fsfotFfxhAot5" target="_blank">Reforma 222 piso 1<br> col. Juarez. Del.</a></li>
                <li>Cuauhtemoc C.P. 06600</li>
                <li><a href="tel: 5539059755" target="_blank">5539059755</a></li>
                <li><a href="mailto: mexico@itps.one" target="_blank">mexico@itps.one</a></li>
              </ul>
            </div>
            <div className="col-lg-3">
              <h5>Argentina</h5>
              <ul className="list-inline">
                <li><a href="https://goo.gl/maps/dEJpszkRkRQ5LZo7A" target="_blank">Olazabal 1515 – 11o piso of 1107,</a></li>
                <li>Buenos Aires, Argentina</li>
                <li><a href="tel: +5411 4785-9800" target="_blank">+5411 4785·9800</a> / <a href="tel: +5411 4782-1087" target="_blank">4782·1087</a></li>
                <li><a href="mailto: argentina@itps.one" target="_blank">argentina@itps.one</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-5">
          <form ref="form" @submit.prevent="sendEmail">
            <div class="form-group">
              <input type="text" name="name" id="name" v-model="name"/>
              <label for="input" class="control-label">Nombre</label><i class="bar"></i>
            </div>
            <div class="form-group">
              <input type="email" name="email" id="email" v-model="email" />
              <label for="input" class="control-label">Email</label><i class="bar"></i>
            </div>
            <div class="form-group">
              <input type="number" name="phone" id="phone" v-model="phone" />
              <label for="input" class="control-label">Teléfono</label><i class="bar"></i>
            </div>
            <div class="form-group">
              <input type="text" name="company" id="company" v-model="company" />
              <label for="input" class="control-label">Empresa</label><i class="bar"></i>
            </div>
            <div class="form-group">
              <input type="text" name="country" id="country" v-model="country" />
              <label for="input" class="control-label">País</label><i class="bar"></i>
            </div>
            <div class="form-group">
              <input type="text" name="message" id="message" v-model="message" />
              <label for="input" class="control-label">Mensaje</label><i class="bar"></i>
            </div>
            <p data-aos="fade-up" className="checkform" v-if="errors.length">
              <strong>Por favor, corrija el(los) siguiente(s) error(es):</strong>
              <ul className="list-inline">
                <li v-for="error in errors">{{ error }}</li>
              </ul>
            </p>
            <div class="g-recaptcha" data-sitekey="6Ld5mIUeAAAAAOKiJOQKnypP1Abd-FY075ArCV0t"></div>
            <input type="submit" id="button-submit" class="custom-btn btn-15 mt-3" :class="btnBackground" value="Enviar" />
          </form>
          <p v-if="success" data-aos="fade-up" class="btnAnimate btnSuccess" >{{ success }}</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import emailjs from "@emailjs/browser";
export default {
  data() {
    return {
      btnBackground: "btnAnimate",
      errors: [],
      name: null,
      email: null,
      phone: null,
      company: null,
      country: null,
      message: null,
      success: '',
    };
  },
  methods: {
    sendEmail() {
      const response = grecaptcha.getResponse();
      if (
        this.name &&
        this.email &&
        this.phone &&
        this.company &&
        this.country &&
        this.message &&
        response.length == 0
      ) {
        emailjs
        .sendForm(
          "service_73q535g",
          "template_xstqvqo",
          this.$refs.form,
          "user_QhOjUIRzSUCTR9AcX0yWh"
        )
        .then(
          (result) => {
            console.log("SUCCESS!", result.text);
            this.success = 'Muchas gracias por su consulta, nos estaremos comunicando con usted lo más pronto posible.';
            const btn = document.getElementById('button-submit');
            btn.addEventListener('click', function(event){
              event.target.disabled = true;
            });
          },
          (error) => {
            console.log("FAILED...", error.text);
          }
        );
      }
      this.errors = [];
      
      if (!this.name) {
        this.errors.push("El nombre es obligatorio.");
      }
      if (!this.email) {
        this.errors.push("El correo es obligatorio");
      }
      if (!this.phone) {
        this.errors.push("El teléfono es obligatorio");
      }
      if (!this.company) {
        this.errors.push("La compañia es obligatoria");
      }
      if (!this.country) {
        this.errors.push("El país es obligatorio");
      }
      if (!this.message) {
        this.errors.push("El mensaje es obligatorio");
      }
      if (response.length == 0) {
        this.errors.push("Por favor, verifique que no es un robot.");
      }
    },
  },
};
</script>

<style>
.form-radio,
.form-group {
  position: relative;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.form-group input {
  height: 1.9rem;
}
.form-group .control-label {
  position: absolute;
  top: 0.25rem;
  pointer-events: none;
  padding-left: 0.125rem;
  z-index: 1;
  color: #b3b3b3;
  font-size: 1rem;
  font-weight: normal;
  -webkit-transition: all 0.28s ease;
  transition: all 0.28s ease;
}
.form-group .bar {
  position: relative;
  border-bottom: 0.0625rem solid #999;
  display: block;
}
.form-group .bar::before {
  content: "";
  height: 0.125rem;
  width: 0;
  left: 50%;
  bottom: -0.0625rem;
  position: absolute;
  background: -webkit-linear-gradient(
    right,
    rgba(19, 132, 193, 1) 27%,
    rgba(10, 212, 147, 1) 60%
  );
  -webkit-transition: left 0.28s ease, width 0.28s ease;
  transition: left 0.28s ease, width 0.28s ease;
  z-index: 2;
}
.form-group input,
.form-group textarea {
  display: block;
  background: none;
  padding: 0.125rem 0.125rem 0.0625rem;
  font-size: 1rem;
  border-width: 0;
  border-color: transparent;
  line-height: 1.9;
  width: 100%;
  color: transparent;
  -webkit-transition: all 0.28s ease;
  transition: all 0.28s ease;
  box-shadow: none;
}
.form-group input:focus,
.form-group input:valid {
  color: #333;
}
.form-group input:focus ~ .control-label,
.form-group input:valid ~ .control-label {
  font-size: 0.8rem;
  color: #333;
  top: -1rem;
  left: 0;
}

.form-group input:focus {
  outline: none;
}
.form-group input:focus ~ .control-label {
  color: #337ab7;
}
.form-group input:focus ~ .bar::before {
  width: 100%;
  left: 0;
}
.bg_gray_form {
  background-color: #ebebeb;
  padding: 3rem 0;
}

@media(min-width: 1000px){
  .bg_gray_form{
    height: 90vh;
    display: flex;
    align-items: center;
  }
  .bg_gray_form h2{
    font-size: 8vh;
  }
}

.btn-submit {
  border: none;
  position: relative;
  display: inline-block;
  width: 150px;
  height: 50px;
  text-align: center;
  color: #fff;
  font-size: 2vh;
  text-transform: uppercase;
  text-decoration: none;
  font-family: sans-serif;
  box-sizing: border-box;
  background: linear-gradient(
    90deg,
    rgba(19, 132, 193, 1),
    rgba(10, 212, 147, 1),
    rgba(19, 132, 193, 1),
    rgba(10, 212, 147, 1)
  );
  background-size: 400%;
  border-radius: 10px;
  z-index: 1;
  display: flex;
  justify-content: center;
  margin-left: auto;
}

.btn-submit:hover {
  animation: animate 8s linear infinite;
}

@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}

.btn-submit:before {
  content: "";
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  z-index: -1;
  background: linear-gradient(
    90deg,
    rgba(19, 132, 193, 1),
    rgba(10, 212, 147, 1),
    rgba(19, 132, 193, 1),
    rgba(10, 212, 147, 1)
  );
  background-size: 400%;
  border-radius: 40px;
  opacity: 0;
  transition: 0.5s;
}

.btn-submit:hover:before {
  filter: blur(20px);
  opacity: 1;
  animation: animate 8s linear infinite;
}
footer h5 {
  font-size: 18px;
}

footer li,
footer li a{
  font-size: 1.4vh;
  color: var(--gray);
  transition: 0.3s ease;
}
footer li a:hover{
  color: var(--green);
}
@media(max-width: 1080px){
  footer li{
    font-size: 15px;
  }
  footer h5{
    font-size: 20px;
  }
}
.checkform{
  background-color: #fff;
  border: 1px solid red;
  color: red;
  font-size: 10px;
  padding: 15px;
}
.checkform strong{
  font-size: 15px;
}
.checkform li{
  color: red;
  font-size: 13px;
}
.btnSuccess{
  border-radius: 10px;
    color: #fff;
    padding: 10px;
    font-size: 15px;
    font-weight: 900;
    text-align: center;
    margin-top: 20px;
}
</style>